import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import uniqBy from 'lodash/uniqBy';
import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';
import { getFormValues } from 'redux-form';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import styles from './HouseholdExpenses.module.scss';
import HouseholdExpense from './HouseholdExpense';
import { DEFAULT_HOUSEHOLD_EXPENSE } from './constants';

const createHouseholdApplicantsSelector = () => state => {
  const expensesFormValues = getFormValues('expensesForm')(state);
  return map(expensesFormValues?.householdExpenses, householdExpense => householdExpense?.household?.applicants);
};

const HouseholdExpenses = ({
 fields, hasEditPermission, applicants,
}) => {
  const themeContext = useContext(ThemeContext);
  const householdApplicantsFormValues = useSelector(useMemo(() => createHouseholdApplicantsSelector(), []), isEqual);

  const hasEmptyHouseholdApplicants = useMemo(() => !isEmpty(householdApplicantsFormValues)
    && householdApplicantsFormValues?.some(householdApplicants => isEmpty((householdApplicants || []))),
    [householdApplicantsFormValues]);

  const allSelectedApplicantsIds = useMemo(() => uniqBy(flatMap(householdApplicantsFormValues)),
    [householdApplicantsFormValues]);

  const availableApplicants = useMemo(() => applicants?.filter(applicant =>
      !includes(allSelectedApplicantsIds, applicant.id)), [allSelectedApplicantsIds, applicants]);

  return (
    <div className={styles.householdExpenseWrapper}>
      {fields.map((field, index) => (
        <HouseholdExpense
          key={`${field}`}
          index={index + 1}
          fieldNamePrefix={field}
          applicants={applicants}
          allSelectedApplicantsIds={allSelectedApplicantsIds}
          currentSelectedApplicantsIds={householdApplicantsFormValues[index] || []}
          handleRemove={() => fields.remove(index)}
          displayRemoveButton={hasEditPermission && fields.length > 1}
        />
      ))}
      {hasEditPermission && availableApplicants?.length > 0 && (
        <div className={styles.buttonWrapper}>
          <SecondaryButton
            onClick={() => fields.push(DEFAULT_HOUSEHOLD_EXPENSE)}
            disabled={hasEmptyHouseholdApplicants}
            className={styles.addButton}
          >
            <div className={styles.buttonLabel}>Add another household</div>
            <Icon
              name="add"
              color={themeContext.palette.text01}
              size="18"
            />
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

HouseholdExpenses.propTypes = {
  fields: PropTypes.object.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
};

export default HouseholdExpenses;
