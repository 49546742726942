import { ApolloProvider } from '@apollo/client';
import React, { PureComponent } from 'react';
import { client } from 'shared/api/GraphQL';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import { locationShape } from 'shapes/reactRouterShapes';
import ErrorBoundary from 'shared/components/ErrorBoundary/ErrorBoundary';
import RetryComponent from 'shared/components/RetryComponent';
import ToastProvider from 'shared/components/ToastProvider/ToastProvider';
import Routes from 'shared/components/Routes/routes';
import { ConstructKit, MuseoSans } from '@construct-kit/core';
import styled from 'styled-components';
import LoggingIn from './pages/LoggingIn';
import { LOGGED_IN } from './constants';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import { checkAuthenticatedUserProfile } from './redux/login';
import SystemNotification from './shared/components/SystemNotification';

const GlobalError = styled(RetryComponent)`
  margin: 2em;
  width: calc(100% - 4em);
`;

const globalError = <GlobalError description="The information is unavailable at the moment, it could be because of a temporary issue. Please try refreshing or come back in a few minutes." />;

export class App extends PureComponent {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatchCheckAuthenticatedUserProfile, location } = this.props;

    const businessId = new URLSearchParams(location.search).get('businessId');
    dispatchCheckAuthenticatedUserProfile(businessId);
  }

  render() {
    const { loginStatus, toggles } = this.props;
    return (
      <ApolloProvider client={client}>
        <ConstructKit disableCssReset>
          <MuseoSans />
          <ToastProvider>
            <ErrorBoundary
              onCatchingError={global.newrelic.noticeError}
              fallback={globalError}
            >
              <SystemNotification />
              {loginStatus === LOGGED_IN ? (
                <Routes toggles={toggles} />
              ) : (
                <Route path="/" render={props => <LoggingIn {...props} />} />
              )}
            </ErrorBoundary>
          </ToastProvider>
        </ConstructKit>
      </ApolloProvider>
    );
  }
}

App.propTypes = {
  loginStatus: PropTypes.string.isRequired,
  dispatchCheckAuthenticatedUserProfile: PropTypes.func.isRequired,
  location: locationShape.isRequired,
  toggles: PropTypes.object,
};

App.defaultProps = {
  toggles: {},
};

const mapStateToProps = state => ({
  loginStatus: state.login.loginStatus,
  toggles: get(state, 'profile.toggles', {}),
});

const mapDispatchToProps = dispatch => ({
  dispatchCheckAuthenticatedUserProfile: businessId =>
    dispatch(checkAuthenticatedUserProfile(businessId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
