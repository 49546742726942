import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import maxBy from 'lodash/maxBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import isInteger from 'lodash/isInteger';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { formatCurrency } from 'utils/formatters';
import concat from 'lodash/concat';
import split from 'lodash/split';
import { PHONE_TYPE } from '../../constants';
import { applicationDisplayStatus, applicationStatus, applicationStatusKeys } from '../constants/applicationStatus';
import { PERMISSIONS } from '../../shared/components/authorisation/permissions';
import hasPermissionTo from '../../shared/components/authorisation/hasPermissionTo';
import { BACKEND_BASE_URL } from '../../config/environment';

export const NOT_APPLICABLE = 'N/A';

export const DATE_FORMAT_STRING = 'D MMMM YYYY h:mm A';

const emptyStringToNA = inputString => (isEmpty(inputString) ? NOT_APPLICABLE : inputString);

export const formatDateTime = (dateString, formatString) => moment(dateString).format(formatString);

const getTimelineStepsWithPreSubmission = (palette) => ([
  {
    key: applicationStatusKeys.PRE_SUBMISSION,
    highlightBackgroundColor: palette.background05,
    highlightColor: palette.background02,
    stageOrder: 0,
  },
  {
    key: applicationStatusKeys.DRAFT,
    highlightBackgroundColor: palette.background05,
    highlightColor: palette.background02,
    stageOrder: 1,
  },
  {
    key: applicationStatusKeys.SUBMITTED,
    highlightBackgroundColor: palette.background05,
    highlightColor: palette.background02,
    stageOrder: 2,
  },
  {
    key: applicationStatusKeys.CONDITIONALLY_APPROVED,
    highlightBackgroundColor: palette.background05,
    highlightColor: palette.background02,
    stageOrder: 3,
  },
  {
    key: applicationStatusKeys.UNCONDITIONALLY_APPROVED,
    highlightBackgroundColor: palette.background05,
    highlightColor: palette.background02,
    stageOrder: 4,
  },
  {
    key: applicationStatusKeys.SETTLED,
    highlightBackgroundColor: palette.background05,
    highlightColor: palette.background02,
    stageOrder: 5,
  },
  {
    key: applicationStatusKeys.DECLINED_OR_WITHDRAWN,
    highlightBackgroundColor: '#ffe0e0',
    highlightColor: palette.background06,
    stageOrder: 5,
  },
]);

export const validateIgnoringSpaces = (regex, value) => {
  const valueWithoutSpace = value.replace(/ /g, '');
  return valueWithoutSpace.match(regex) !== null;
};

export const generateLoanRequestName = (index, productName) => {
  const loanNameStr = isEmpty(productName) ? '' : ` - ${productName}`;
  return `Loan ${index + 1}${loanNameStr}`;
};

export const generateLoanLink = loan => (loan.id ? {
    text: isEmpty(loan.accountNumber) ? 'Undefined' : loan.accountNumber,
    link: `/loans/${loan.id}`,
  } : null);

export const getTimelineArrowItems = (applicationStatusMap, palette) =>
  generateTimelineArrowItems(applicationStatusMap, getTimelineStepsWithPreSubmission(palette));

export const generateTimelineArrowItems = (applicationStatusMap, timeLineToMap) => {
  const { actionTimelineMap: timeline, status: finalStatus } = applicationStatusMap;
  // merge declined and withdraw(leaving the latest)
  const isStagePresent = (stage = {}) => !isEmpty(stage.statusDateTime);
  const mergedTimeline = {
    ...timeline,
    [applicationStatusKeys.DECLINED_OR_WITHDRAWN]: maxBy(
      filter([timeline.WITHDRAWN, timeline.DECLINED], it => isStagePresent(it)),
      it => (new Date(it.statusDateTime)),
    ),
  };
  delete mergedTimeline.WITHDRAWN;
  delete mergedTimeline.DECLINED;
  let finalStageKey = finalStatus;
  if (finalStageKey === applicationStatus.WITHDRAWN || finalStageKey === applicationStatus.DECLINED) {
    finalStageKey = applicationStatusKeys.DECLINED_OR_WITHDRAWN;
  }

  const finalStage = find(timeLineToMap, { key: finalStageKey });
  const shouldAutoFillStatus = finalStage.key !== applicationStatusKeys.DECLINED_OR_WITHDRAWN;
  return timeLineToMap.map(({
                              highlightBackgroundColor, highlightColor, stageOrder, key,
                            }) => {
    const isLessOrEqThanFinalStage = stageOrder < finalStage.stageOrder || key === finalStage.key;
    const isPreSubmissionStage = key === applicationStatusKeys.PRE_SUBMISSION;
    const shouldHighlight = isLessOrEqThanFinalStage
    && (isPreSubmissionStage || shouldAutoFillStatus || isStagePresent(mergedTimeline[key]));

    return {
      label: applicationDisplayStatus[key],
      backgroundColor: shouldHighlight ? highlightBackgroundColor : undefined,
      color: shouldHighlight ? highlightColor : undefined,
    };
  });
};

const formatApplicationUpdate = update => ({
  ...update,
  timestamp: formatDateTime(update.timestamp, DATE_FORMAT_STRING),
  receivedTimestamp: formatDateTime(update.receivedTimestamp, DATE_FORMAT_STRING),
  receivedDate: new Date(update.receivedTimestamp),
  messageAnnotations: (update.messageAnnotations || [])
    .map((annotation, index) => ({
      ...annotation,
      id: `${update.id }-${ index}`,
      value: annotation.value && annotation.value.startsWith('\r\n') ? annotation.value.replace('\r\n', '') : annotation.value,
    })),
});

const formatApplicantPhones = (applicant) => {
  const applicantPhoneMapping = [
    {
      type: PHONE_TYPE.MOBILE,
      source: 'mobile',
    },
    {
      type: PHONE_TYPE.HOME,
      source: 'homePhone',
    },
    {
      type: PHONE_TYPE.WORK,
      source: 'workPhone',
    },
  ];

  return applicantPhoneMapping.reduce((res, { type, source }) => {
    const number = applicant[source];
    res[type.name] = (isNil(number) ? undefined : {
        number,
        isPreferred: type.value === applicant.preferredPhone,
      });
      return res;
    }, {});
};

export const formatApplicationStatus = status => ({
  ...status,
  actionTimelineMap: mapValues(status.actionTimelineMap,
    item => ({
      ...item,
      rawDateTime: (item && item.statusDateTime) ? new Date(item.statusDateTime) : null,
      statusDateTime: (item && item.statusDateTime) ? formatDateTime(item.statusDateTime, DATE_FORMAT_STRING) : '',
    })),
  applyOnlineTimelineMap: mapValues(status.applyOnlineTimelineMap,
    item => ({
      ...item,
      rawDateTime: item ? new Date(item.statusDateTime) : null,
      statusDateTime: item ? formatDateTime(item.statusDateTime, DATE_FORMAT_STRING) : NOT_APPLICABLE,
    })),
});

const formatPersonalApplicantList = applicantList =>
  applicantList.map(applicant => ({
    ...applicant,
    ...formatApplicantPhones(applicant),
    isSurveyContact: get(applicant, 'isSurveyContact', false),
  }));

export const sortApplicantListByPrimaryAndName = applicantList =>
  orderBy(applicantList.map(applicant => ({
    ...applicant,
    isPrimary: isNil(applicant.isPrimary) ? false : applicant.isPrimary,
  })), ['isPrimary', 'isPerson', 'name'], ['desc', 'desc', 'asc']);

const formatCompanyApplicants = (companyApplicants) => companyApplicants
  .map(companyApplicant => ({
      ...companyApplicant,
      beneficiaries: (get(companyApplicant, 'beneficiaries', []) || []),
    }));
export const formatApplicantsOrGuarantors = (applicants) => ({
  persons: formatPersonalApplicantList(get(applicants, 'persons', [])),
  companies: formatCompanyApplicants(get(applicants, 'companies', [])),
});

export const formatApplication = app => ({
    ...app,
    applicants: formatApplicantsOrGuarantors(get(app, 'applicants', {})),
    guarantors: formatApplicantsOrGuarantors(get(app, 'guarantors', {})),
    totalLoanAmount: formatCurrency(app.totalLoanAmount, { precision: isInteger(app.totalLoanAmount) ? 0 : 2 }),
    applicationStatus: formatApplicationStatus(app.applicationStatus),
    applicationUpdates: sortBy(map(app.applicationUpdates, formatApplicationUpdate), ['receivedDate'])
      .reverse(),
  });

export const hasPermission = (businessId, permissions, requiredPermissions) =>
  !!(businessId && requiredPermissions.reduce(
    (res, permission) => res || hasPermissionTo(permission, permissions, businessId), false,
  ));

export const applicationEditPermissions = [
  PERMISSIONS.EDIT_ALL_APPLICATION_TRACKING,
  PERMISSIONS.EDIT_OWN_APPLICATION_TRACKING,
];

export const hasEditApplicationPermission = (businessId, permissions) =>
  hasPermission(
    businessId,
    permissions,
    applicationEditPermissions,
  );

export const additionalApplicationEditPermissions = [PERMISSIONS.EDIT_ALL_APPLICATION_ADDITIONAL];

export const hasAdditionalEditApplicationPermission = (businessId, permissions) =>
  hasPermission(
    businessId,
    permissions,
    additionalApplicationEditPermissions,
  );

export const getCombinedApplicants = applicants => concat(
  get(applicants, 'persons', []).map(person => ({ isPerson: true, ...person })),
  get(applicants, 'companies', []).map(company => ({ isPerson: false, ...company })),
);

export const getCombinedGuarantors = guarantors => concat(
  get(guarantors, 'persons', []),
  get(guarantors, 'companies', []),
);

export const getCombinedApplicantIds = applicants => getCombinedApplicants(applicants).map(applicant => applicant.id);

export const getPersonApplicantIds = applicants => applicants?.persons?.map(applicant => applicant.id) ?? [];

export const generateAOLURL = identifier =>
  `${BACKEND_BASE_URL}/NextGenPortal?displayID=${identifier}`;

export const getNextGenIdentifier = applicationSource => get(split(applicationSource, '::'), [1], '');

const LAUNCHPAD_SOURCE_PREFIX = 'launchpad::';
export const isApplicationLaunchpadCreated = (applicationSource) =>
  applicationSource.startsWith(LAUNCHPAD_SOURCE_PREFIX);

export default emptyStringToNA;

const toAnnualValueTransformers = {
  Weekly: value => value * 52,
  Fortnightly: value => value * 26,
  Monthly: value => value * 12,
  Yearly: value => value,
};

export const toAnnualValue = (frequency, value = 0) =>
  (toAnnualValueTransformers[frequency] ? toAnnualValueTransformers[frequency](value) : value);

const toMonthlyValueTransformers = {
  Weekly: value => (value * 52) / 12,
  Fortnightly: value => (value * 26) / 12,
  Monthly: value => value,
  Yearly: value => value / 12,
};

export const toMonthlyValue = (frequency, value = 0) =>
  (toMonthlyValueTransformers[frequency] ? toMonthlyValueTransformers[frequency](value) : value);

/*
 AnnualGrossIncome = (Prev Slabs Max Gross Income +
 (AnnualNetIncome  - Max NI on Prev Slab) / (1 - Curr Slab IT Rate - Curr Slab Medicare Levy Rate)
 Default value: MediCareLevyDeducted = "Yes", ScaleYear = "Current"
*/

export const convertNetIncomeToGrossIncome = annualNetIncome => {
  if (annualNetIncome <= 17836) {
    return annualNetIncome / (1 - 0.02);
  }
  if (annualNetIncome <= 39812) {
    return 18200 + (annualNetIncome - 17836) / (1 - 0.16 - 0.02);
  }
  if (annualNetIncome <= 101012) {
    return 45000 + (annualNetIncome - 39812) / (1 - 0.3 - 0.02);
  }
  if (annualNetIncome <= 134562) {
    return 135000 + (annualNetIncome - 101012) / (1 - 0.37 - 0.02);
  }
  if (annualNetIncome > 134562) {
    return 190000 + (annualNetIncome - 134562) / (1 - 0.45 - 0.02);
  }
  return 0;
};

export const filterApplicantOwnedItemsByPercentage = (applicantIds, items) => (
  items?.filter(item => item.ownerships.some(
    ownership => applicantIds.includes(ownership.applicantId) && ownership.percentage > 0,
    )) ?? []
);

export const filterApplicantOwnedItems = (applicantIds, items) => (
  items?.filter(item => item.ownerships.some(
    ownership => applicantIds.includes(ownership.applicantId),
    )) ?? []
);

export const calApplicantOwnershipsPercentage = (applicantIds, ownerships) => (
  sumBy(ownerships.filter(ownership => applicantIds.includes(ownership.applicantId)), 'percentage') / 100
);
