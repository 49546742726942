import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import styles from './ConfirmDeleteModal.module.scss';
import Modal from '../../../../shared/components/Modal';
import SecondaryButton from '../../../../shared/components/Buttons/SecondaryButton';
import Icon from '../../../../shared/components/Icon/Icon';

export const ConfirmDeleteModal = ({
  onRequestClose, onConfirmed, isOpen,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header={<div>Delete categories</div>}
      className={styles.confirmDeleteModal}
      footer={(
        <div className={styles.buttonsContainer}>
          <SecondaryButton
            onClick={onConfirmed}
            className={styles.deleteButton}
            tabIndex={0}
          >
            Yes Delete
            <Icon name="delete" size="18" color={themeContext.palette.error01} />
          </SecondaryButton>
          <SecondaryButton
            onClick={onRequestClose}
            className={styles.cancelButton}
            tabIndex={0}
            autoFocus
          >
            No don&apos;t delete
          </SecondaryButton>
        </div>
      )}
    >
      <div className={styles.message}>
        <p>
          Warning!
        </p>
        <p>
          Deleting a category here will delete it from all loans.<br />
          Do you wish to the delete category from all loans?
        </p>
      </div>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
