import React, { useState, useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Icon from 'shared/components/Icon/Icon';
import styles from './EditableContent.module.scss';
import Modal from '../Modal/Modal';
import PrimaryButton from '../Buttons/PrimaryButton';
import TextInput from '../formFields/TextInput/TextInput';

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

const defaultValidator = () => true;

const EditableContent = ({
  content, label, maxLength, onSave, onValidate, placeholder, errorMessage,
}) => {
  const themeContext = useContext(ThemeContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inputValue, setInputValue] = useState(content || '');
  const [isValid, setValid] = useState(true);

  const openModal = useMemo(() => () => {
    setInputValue(content || '');
    setValid(onValidate(content || ''));
    setIsEditing(true);
  }, [setIsEditing, content, setInputValue, setValid, onValidate]);

  const closeModal = useMemo(() => () => setIsEditing(false), [setIsEditing]);

  const handleKeyDown = useMemo(() => (e) => {
    [ENTER_KEY_CODE, SPACE_KEY_CODE].filter(code => code === e.keyCode).map(openModal);
  }, [openModal]);

  const handleSave = () => {
    const trimmedValue = inputValue.trim();
    setInputValue(trimmedValue);
    if ((isEmpty(trimmedValue) && isEmpty(content)) || trimmedValue === content) {
      closeModal();
      return;
    }

    const validation = onValidate(trimmedValue);
    setValid(validation);
    if (validation) {
      setIsSaving(true);
      onSave(trimmedValue).then(() => {
        closeModal();
      }).finally(() => setIsSaving(false));
    }
  };

  const handleValueChange = (value) => {
    const validation = onValidate(value.trim());
    if (validation) {
      setValid(validation);
    }
   setInputValue(value);
  };

  return (
    <>
      <div
        className={styles.contentWrapper}
        role="button"
        tabIndex={0}
        onClick={openModal}
        onKeyDown={handleKeyDown}
        data-cy={label.replace(/\s/g, '')}
      >
        {isEmpty(content) ? (
          <span className={styles.placeholder}>{placeholder}</span>
        ) : (
          <span className={styles.content}>{content}</span>
        )}
        <div className={styles.editIcon}>
          <Icon name="edit" color={themeContext.palette.information01} size="12px" />
        </div>
      </div>
      <Modal
        isOpen={isEditing}
        header="Edit field"
        onRequestClose={closeModal}
        footer={(
          <PrimaryButton
            className={styles.saveButton}
            onClick={handleSave}
            disabled={isSaving}
            loading={isSaving}
          >
            Save
          </PrimaryButton>
        )}
      >
        <div className={styles.bodyWrapper}>
          <TextInput
            className={styles.inputWrapper}
            label={label}
            onChange={handleValueChange}
            value={inputValue}
            maxLength={maxLength}
            errorMessage={isValid ? null : errorMessage}
          />
        </div>
      </Modal>
    </>
  );
};

EditableContent.defaultProps = {
  content: '',
  label: '',
  placeholder: '',
  maxLength: null,
  errorMessage: 'Invalid input.',
  onValidate: defaultValidator,
};

EditableContent.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  errorMessage: PropTypes.string,
  onValidate: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

export default EditableContent;
