import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { PADDING, PALETTE } from 'shared/theme/depricatedStyles';
import Fonts from 'shared/theme/depricatedStyles/Fonts';

/* eslint-disable no-nested-ternary */
/* because this is the way they do it in the react-select docs and I think it is neat */
const getOptionBackgroundColor = ({ isFocused, isSelected }, palette) =>
  (isSelected
    ? PALETTE.primaryBackground
    : isFocused
      ? palette.hover02
      : palette.background03);

const HeaderSelect = ({
  onChange, menuPortalTarget, options, selectedValue, classNamePrefix,
}) => {
  const themeContext = useContext(ThemeContext);
  const selectedOption = options.find(o => o.value === selectedValue);

  const customStyles = {
    dropdownIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' }),
    control: provided => ({
      ...provided,
      boxShadow: 'none',
      borderStyle: 'none',
      minHeight: '1.0rem',
      backgroundColor: themeContext.palette.background03,
      padding: '0 7px',
    }),
    valueContainer: provided => ({
      ...provided,
      justifyContent: 'flex-end',
      padding: PADDING.xxsmall,
    }),
    singleValue: provided => ({
      ...provided,
      color: themeContext.palette.information01,
      lineHeight: 1.4,
    }),
    menuPortal: provided => ({
      ...provided,
      zIndex: 501,
      right: 0,
    }),
    menu: provided => ({
      ...provided,
      width: '9rem',
      right: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: getOptionBackgroundColor(state, themeContext.palette),
      color: state.isFocused || state.isSelected
        ? themeContext.palette.hover03 : themeContext.palette.text01,
      fontSize: Fonts.size.regular,
      padding: 'var(--space-small)',
      textAlign: 'left',
    }),
  };

  return (
    <ReactSelect
      value={selectedOption}
      styles={customStyles}
      classNamePrefix={classNamePrefix}
      onChange={option => onChange(option.value)}
      menuPortalTarget={menuPortalTarget}
      options={options}
      isClearable={false}
    />
  );
};
HeaderSelect.defaultProps = {
  menuPortalTarget: undefined,
  classNamePrefix: undefined,
};

HeaderSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  menuPortalTarget: PropTypes.instanceOf(Element),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classNamePrefix: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
  })).isRequired,
};

export default HeaderSelect;
