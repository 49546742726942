import React, {
 useState, useMemo, useEffect, useContext,
} from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import TextArea from 'shared/components/formFields/TextArea';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import DialogBubble from 'shared/components/DialogBubble/DialogBubble';
import isEmpty from 'lodash/isEmpty';
import {
  getUTCNow,
  toDateStr,
  toTimeStrWithoutDate,
  toWeekAndDayMonthStr,
  toYear,
} from 'utils/datetime';
import styles from './CommentHistory.module.scss';

const commentHistorySection = (comments) => {
  const groupedComments = groupBy(comments, comment => (comment.createdAt ? toDateStr(comment.createdAt) : 0));
  return (
    <div className={styles.commentContainer}>
      {keys(groupedComments).sort().reverse().map(date => (
        <div key={date}>
          {groupedComments[date]
            .sort((comment1, comment2) => new Date(comment2.createdAt || 0) - new Date(comment1.createdAt || 0))
            .map(commentItem)}
        </div>
      ))}
    </div>
  );
};

const commentItem = (comment, index) => {
  const themeContext = useContext(ThemeContext);
  const commentDateTime = comment.createdAt && index === 0 ? (
    <div className={styles.commentDateTime}>
      <span className={styles.commentDate}>{`${toWeekAndDayMonthStr(comment.createdAt)}`}</span>
      <span className={styles.commentYear}>{`${toYear(comment.createdAt)}`}</span>
    </div>
  ) : (<div className={styles.commentDateTime} />);
  const commentTitle = comment.createdAt ? `${[toTimeStrWithoutDate(comment.createdAt), comment.name].join(' by ')}` : comment.name;

  return (
    <div key={index} className={styles.commentWrapper}>
      <div className={styles.left}>
        <div className={styles.iconWrapper}>
          <Icon name="note-circle" size="2.4rem" color={themeContext.palette.text01} />
        </div>
      </div>
      <div className={styles.right}>
        {commentDateTime}
        <DialogBubble className={styles.commentBubble}>
          <div className={styles.commentTitle}>{commentTitle}</div>
          <pre className={styles.commentContent}>{`${comment.content}`}</pre>
        </DialogBubble>
      </div>
    </div>
  );
};

const CommentHistory = (props) => {
  const { onAppendComment, comments, taskId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [newComment, setNewComment] = useState('');
  const error = useMemo(() => (isEmpty(newComment.trim()) ? '' : null), [newComment]);
  const [touched, touch] = useState(false);
  useEffect(() => {
    setNewComment('');
  }, [comments]);

  const handleAppendComment = () => {
    if (isEmpty(error)) {
      if (!isEmpty(newComment.trim())) {
        const commentObj = {
          taskId,
          createdAt: getUTCNow(),
          content: newComment.trim(),
        };
        setIsLoading(true);
        onAppendComment(commentObj).finally(() => {
          setIsLoading(false);
        });
      }
    } else {
      touch(true);
    }
  };

  return (
    <div className={styles.modalFooter}>
      <TextArea
        key={comments.length}
        className={styles.commentTextArea}
        label="Comments"
        value={newComment}
        onChange={setNewComment}
        errorMessage={(touched && error) ? error : ''}
        onBlur={() => touch(true)}
      />
      <div className={styles.buttonGroup}>
        <SecondaryButton
          loading={isLoading}
          onClick={handleAppendComment}
        >
          Add comment
        </SecondaryButton>
      </div>
      {commentHistorySection(comments)}
    </div>
  );
};

CommentHistory.defaultProps = {
  comments: [],
};

CommentHistory.propTypes = {
  onAppendComment: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  comments: PropTypes.array,
};

export default CommentHistory;
