import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import { ConnectedCategoriesManagementModal } from 'LoanCategories/components/CategoriesManagementModal';
import DownloadExcelButton from 'shared/components/DownloadExcelButton';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import { Button } from '@construct-kit/core';
import styles from './styles.module.scss';
import UpdateFixedTrailButton from '../LoanSetFixedTrail/UpdateFixedTrailButton';
import SetFixedTrailModal from '../LoanSetFixedTrail/SetFixedTrailModal';
import RemoveFixedTrailModal from '../LoanSetFixedTrail/RemoveFixedTrailModal';

const LoansToolBar = ({
                        showCategories,
                        showUpdateFixedTrail,
                        disableDownloadReport,
                        onCategoriesUpdated,
                        onLoansExport,
                        loanSelection,
                      }) => {
  const [showCategoriesManagementModal, toggleShowCategoriesManagementModal] = useState(false);
  const [showSetTrailModal, toggleShowSetTrailModal] = useState(false);
  const [showRemoveTrailModal, toggleShowRemoveTrailModal] = useState(false);
  return (
    <div className={styles.header}>
      <h1>Loans</h1>
      <div className={styles.toolBar}>
        {showUpdateFixedTrail && (
        <>
          <Button
            className={styles.nakedButton}
            variant="naked"
            disabled={isEmpty(loanSelection)}
            onClick={() => {
                    toggleShowRemoveTrailModal(true);
                  }}
          >
            Remove fixed trail
          </Button>
          <RemoveFixedTrailModal
            isOpen={showRemoveTrailModal}
            onClose={() => toggleShowRemoveTrailModal(!showRemoveTrailModal)}
          />
          <UpdateFixedTrailButton
            disabled={isEmpty(loanSelection)}
            onClick={() => {
                  toggleShowSetTrailModal(true);
                }}
            text="Set fixed trail"
          />
          <SetFixedTrailModal
            isOpen={showSetTrailModal}
            onClose={() => toggleShowSetTrailModal(!showSetTrailModal)}
          />
        </>
          )}
        <DownloadExcelButton
          name="Download as spreadsheet"
          downloadReport={onLoansExport}
          disabled={disableDownloadReport}
          withIcon={false}
          allowedPermissions={[PERMISSIONS.VIEW_ALL_LOANS]}
        />
        {showCategories && (
        <>
          <PrimaryButton onClick={() => {
                toggleShowCategoriesManagementModal(true);
              }}
          >
            Manage categories
          </PrimaryButton>
          {showCategoriesManagementModal && (
          <ConnectedCategoriesManagementModal
            isOpen
            onRequestClose={() => {
                    toggleShowCategoriesManagementModal(false);
                  }}
            onCategoriesUpdated={onCategoriesUpdated}
          />
              )}
        </>
          )}
      </div>
    </div>
  );
};

LoansToolBar.propTypes = {
  showCategories: PropTypes.bool.isRequired,
  showUpdateFixedTrail: PropTypes.bool.isRequired,
  disableDownloadReport: PropTypes.bool.isRequired,
  onCategoriesUpdated: PropTypes.func,
  onLoansExport: PropTypes.func.isRequired,
  loanSelection: PropTypes.array.isRequired,
};

LoansToolBar.defaultProps = {
  onCategoriesUpdated: null,
};

export default LoansToolBar;
