import classNames from 'classnames/bind';
import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { ThemeContext } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { Chart } from 'shared/components/Chart';
import moment from 'moment';
import { Tooltip } from '@devexpress/dx-react-chart-bootstrap4';
import styles from './styles.module.scss';
import { MessageChart } from '../MessageChart';
import { generateDataForWindow, getMonthName } from '../utils';
import { ValueLabel } from '../ValueLabel';
import { viewShape } from '../../../shapes';
import { perspectiveShape } from '../../../shapes/perspective';
import Icon from '../../../../../shared/components/Icon/Icon';
import TertiaryButton from '../../../../../shared/components/Buttons/TertiaryButton';

const cx = classNames.bind(styles);

const findViewByPath = (views = [], [key, ...rest]) => {
  const result = find(views, { key });
  if (!result || rest.length === 0) {
    return result;
  }
  return findViewByPath(result.subViews, rest);
};

export const ReportChart = React.memo(({
                                         views,
                                         toolTipComponent: ToolTipComponent,
                                         perspectives,
                                       }) => {
  const themeContext = useContext(ThemeContext);
  const [state, setState] = useState({
    viewPath: [get(perspectives, [0, 'key'], null)],
    tooltipTarget: null,
  });
  const [hoverTarget, setHoverTarget] = useState(null);
  const { viewPath, tooltipTarget = null } = state;
  const view = findViewByPath(views, viewPath) || {};
  const {
    data = [], series = [], subViews = [], displayWindow = {},
  } = view;
  const defaultStart = moment().endOf('month');
  const defaultEnd = defaultStart.subtract(1, 'year').startOf('month');
  const { start = defaultStart, end = defaultEnd } = displayWindow;

  const dataToDisplay = data.filter(it => it.key >= start.format('YYYY-MM') && it.key <= end.format('YYYY-MM'));

  useEffect(() => {
    setState({
      viewPath: [get(views, [0, 'key'], null)],
      tooltipTarget: null,
    });
  }, [views]);

  const handleClick = useMemo(() => ({ targets }) => {
    if (isEmpty(subViews)) {
      return;
    }
    const name = get(targets, [0, 'series'], '');
    const key = get(find(series, { name }), 'key', '');
    const targetView = find(subViews, { key });
    if (targetView) {
      setState({ viewPath: [...viewPath, key] });
    }
  }, [setState, subViews, series, viewPath]);

  const handleLegendClick = useMemo(() => (e, { key }) => {
    if (isEmpty(subViews)) {
      return;
    }
    const targetView = find(subViews, { key });
    if (targetView) {
      setState({ viewPath: [...viewPath, key] });
    }
  }, [subViews, setState, viewPath]);

  const handleStepBack = useMemo(() => () => {
    if (viewPath.length > 1) {
      setState({ viewPath: viewPath.slice(0, -1) });
    }
  }, [setState, viewPath]);

  const handleHoverChange = (target) => {
    if (!target) {
      if (hoverTarget) {
        setHoverTarget(null);
      }
      return;
    }
    if (target.series === 'previousYearTotalLoanAmount') {
      setHoverTarget(target);
    } else if (hoverTarget !== null) {
      setHoverTarget(null);
    }
  };

  const handleTooltipTargetChange = useMemo(() => (target) => {
    if (!target) {
      if (tooltipTarget) {
        setState({ viewPath, tooltipTarget: null });
      }
      return;
    }
    if (target.point !== get(tooltipTarget, 'point')
      || [target.series, get(tooltipTarget, 'series')].filter(it => it === 'previousYearTotalLoanAmount').length === 1
    ) {
      const barSeries = series.filter(s => (s.key !== 'previousYearTotalLoanAmount')).reverse();
      const topSerie = target.series === 'previousYearTotalLoanAmount' ? 'previousYearTotalLoanAmount'
        : (barSeries.find(s => get(dataToDisplay, [target.point, s.key], 0) > 0) || barSeries[0]).name;
      setState({
        viewPath,
        tooltipTarget: target
          ? { series: topSerie, point: target.point }
          : null,
      });
    }
  }, [tooltipTarget, viewPath, series, dataToDisplay]);

  const handleEnterSelectPerspective = (e) => {
    if (e.key && e.key === 'Enter') {
      handleSelectPerspective(e);
    }
  };

  const handleSelectPerspective = useMemo(() => (e) => {
    const viewKey = e.target.getAttribute('data-perspectivekey');
    const targetView = find(views, { key: viewKey });
    if (targetView) {
      setState({
        viewPath: [viewKey],
      });
    }
  }, [views]);

  const WrappedTooltipComponent = useMemo(
    () => {
      if (!ToolTipComponent) {
        return Tooltip.Content;
      }
      return React.memo(props => (
        <ToolTipComponent
          {...props}
          data={dataToDisplay}
          series={series}
        />
      ));
    },
  [ToolTipComponent, dataToDisplay, series],
  );

  const empty = !series.some(s => (dataToDisplay.some(d => d[s.key] !== null)));
  if (empty) {
    return (
      <MessageChart
        series={[{
          name: '',
          key: 'fake',
          color: 'transparent',
        }]}
        message={`No data to show for ${start.format('MMM YYYY')} - ${end.format('MMM YYYY')}`}
        data={generateDataForWindow({ start, end }, { fake: 0 })}
      />
    );
  }
  return (
    <div className={styles.chartContainer}>
      <div
        className={styles.perspectives}
      >
        {views.length > 1 && <div className={styles.title}>View</div>}
        {views.length > 1 && views.map(v => (
          <div
            data-perspectivekey={v.key}
            key={v.key}
            className={cx({ selected: v.key === viewPath[0], filterBtn: true })}
            role="button"
            tabIndex={0}
            onKeyDown={handleEnterSelectPerspective}
            onClick={handleSelectPerspective}
          >
            {v.name}
          </div>
        ))}
      </div>
      <div
        className={cx({
          stepBackBtnContainer: true,
          hide: viewPath.length <= 1,
        })}
      >
        <TertiaryButton
          className={styles.stepBackBtn}
          onClick={handleStepBack}
        >
          <Icon
            style={{ marginBottom: -2, marginRight: -5 }}
            color={themeContext.palette.information01}
            name="arrow-left"
          />
          Back
        </TertiaryButton>
      </div>
      <div className={styles.chartTitle}>
        Total loan values($)
      </div>
      <div>
        <Chart
          height={400}
          key={`${view.key}-${series.map(s => s.key).join(',')}`}
          data={dataToDisplay}
          series={series}
          argumentLabelFormatter={getMonthName}
          onLegendClick={handleLegendClick}
          onClick={handleClick}
          hoverTarget={hoverTarget}
          onHoverChange={handleHoverChange}
          valueAxis={{
            labelComponent: ValueLabel,
          }}
          tooltip={{
            contentComponent: WrappedTooltipComponent,
            onTargetItemChange: handleTooltipTargetChange,
            targetItem: tooltipTarget,
          }}
        />
      </div>
    </div>
  );
});

ReportChart.propTypes = {
  toolTipComponent: PropTypes.elementType,
  views: PropTypes.arrayOf(viewShape).isRequired,
  perspectives: PropTypes.arrayOf(perspectiveShape).isRequired,
};

ReportChart.defaultProps = {
  toolTipComponent: undefined,
};
