import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'shared/components/Icon/Icon';
import styles from './styles.module.scss';

const handleMouseDown = (e) => {
  e.currentTarget.style.outline = 'none';
};
const handleBlur = (e) => {
  e.currentTarget.style.outline = '';
};

const TertiaryButton = ({
  children,
  onClick,
  style,
  className,
  icon,
  iconColor,
  disabled,
}) => {
  const themeContext = useContext(ThemeContext);
  const iconColour = iconColor || themeContext.palette.information01;

  return (
    <button
      className={classNames(className, styles.linkButton, {
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      type="button"
      style={{ ...style }}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
    >
      {children}
      {icon && (
        <span>
          <Icon name={icon} color={iconColour} />
        </span>
      )}
    </button>
  );
};

TertiaryButton.defaultProps = {
  style: {},
  className: undefined,
  icon: undefined,
  iconColor: undefined,
  disabled: false,
};

TertiaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  icon: Icon.propTypes.name,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TertiaryButton;
