import HtmlParser from 'react-html-parser';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import HtmlEditor, { Item, Toolbar } from 'devextreme-react/html-editor';
import { PencilMd } from '@construct-kit/core';
import Icon from '../../../../../../../../../shared/components/Icon/Icon';
import styles from './NoteContent.module.scss';
import Modal from '../../../../../../../../../shared/components/Modal';
import PrimaryButton from '../../../../../../../../../shared/components/Buttons/PrimaryButton';
import { ConfirmDeleteNoteModal } from './ConfirmDeleteNoteModal';
import { toDateStrWithoutYear, toTimeStrWithoutDate } from '../../../../../../../../../utils/datetime';
import SecondaryButton from '../../../../../../../../../shared/components/Buttons/SecondaryButton';

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

class NoteContent extends Component {
  constructor(props) {
    super(props);
    this.sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    this.fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
    this.editor = React.createRef();
    const { content } = this.props;
    const { theme } = this.props;
    this.state = {
      isEditNoteModalOpen: false,
      noteContent: content,
      isSavable: false,
      isSaving: false,
      isConfirmDeleteModalOpen: false,
      constructTheme: theme,
    };
  }

  handleEditNote = () => {
    this.setState({
      isEditNoteModalOpen: true,
    }, () => setTimeout(() => this.editor.current && this.editor.current.instance.focus(), 100));
  };

  handleConfirmDeleteNote = () => {
    const { actionTime, deleteNote, id } = this.props;
    const deleteContent = `A note from ${toDateStrWithoutYear(actionTime)} ${toTimeStrWithoutDate(actionTime)} was deleted`;
    deleteNote(deleteContent, id).then(() => {
      this.handleCancelDeleteNote();
    });
  }

  handleCancelDeleteNote = () => {
    this.setState({
      isConfirmDeleteModalOpen: false,
    });
  }

  openDeleteModal = () => {
    this.handleEditNoteClose();
    this.setState({
      isConfirmDeleteModalOpen: true,
    });
  }

  handleKeyDown = (e) => {
    [ENTER_KEY_CODE, SPACE_KEY_CODE].filter(code => code === e.keyCode).map(this.handleEditNote);
  };

  handleEditNoteClose = () => {
    this.setState({
      isEditNoteModalOpen: false,
      isSavable: false,
    });
  };

  noteContentChanged = (e) => {
    const { content } = this.props;
    this.setState({
      noteContent: e.value,
      isSavable: content !== e.value,
    });
  };

  handleUpdateNote = () => {
    const { editNote, content, id } = this.props;
    const { noteContent } = this.state;
    this.setState({
      isSaving: true,
    });
    editNote(noteContent, id).then(() => {
      this.handleEditNoteClose();
      this.setState({
        isSaving: false,
        noteContent: content,
      });
    }).catch(() => {
      this.setState({
        isSaving: false,
      });
    });
  };

  renderEditor() {
    const { content } = this.props;
    return (
      <HtmlEditor
        onValueChanged={this.noteContentChanged}
        ref={this.editor}
      >
        <Toolbar>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item
            name="size"
            acceptedValues={this.sizeValues}
          />
          <Item
            name="font"
            acceptedValues={this.fontValues}
          />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
        </Toolbar>
        { HtmlParser(content) }
      </HtmlEditor>
    );
  }

  render() {
    const {
      isEditNoteModalOpen, isSavable, isSaving, isConfirmDeleteModalOpen, constructTheme,
    } = this.state;
    const { content, editable } = this.props;

    const newFooter = (
      <div className={styles.modalFooter}>
        <PrimaryButton
          className={styles.saveNoteButton}
          onClick={this.handleUpdateNote}
          disabled={!isSavable || isSaving}
        >
          Save
        </PrimaryButton>
        <SecondaryButton
          className={styles.deleteNoteButton}
          onClick={this.openDeleteModal}
        >
          <div className={styles.deleteNoteContent}>
            <span>Delete note</span>
            <Icon name="delete" size="22px" color={constructTheme.palette.error01} />
          </div>
        </SecondaryButton>
      </div>
    );

    return (
      <div className={`custom-html-content ${styles.contentRtf}`}>
        <div className={styles.contentWithoutIcon}>
          {
            HtmlParser(content)
          }
        </div>
        { editable && (
          <>
            <div
              onClick={this.handleEditNote}
              onKeyDown={this.handleKeyDown}
              className={styles.editIcon}
              role="button"
              tabIndex={0}
            >
              <PencilMd color={constructTheme.information01} />
            </div>
            <Modal
              className={styles.applicationNewNoteModal}
              isOpen={isEditNoteModalOpen}
              onRequestClose={this.handleEditNoteClose}
              header={<h3 className={styles.newNoteModalTitle}>Edit note</h3>}
              footer={newFooter}
            >
              <div className={styles.applicationNoteEditor}>
                {this.renderEditor()}
              </div>
            </Modal>
            <ConfirmDeleteNoteModal
              onRequestClose={this.handleCancelDeleteNote}
              onConfirmed={this.handleConfirmDeleteNote}
              isOpen={isConfirmDeleteModalOpen}
            />
          </>
        )}
      </div>
    );
  }
}

NoteContent.defaultProps = {
  editable: false,
};

NoteContent.propTypes = {
  deleteNote: PropTypes.func.isRequired,
  editNote: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  actionTime: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(NoteContent);
