import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Modal from '../../Modal';
import WithOverride from './WithOverride';
import ClickableValue from './ClickableValue';
import NumberInput from '../NumberInput';
import TertiaryButton from '../../Buttons/TertiaryButton';
import Icon from '../../Icon/Icon';

const Footer = ({
 onApply, disableApply,
}) => (
  <div>
    <PrimaryButton
      disabled={disableApply}
      onClick={onApply}
    >
      Apply
    </PrimaryButton>
  </div>
);

Footer.propTypes = {
  onApply: PropTypes.func,
  disableApply: PropTypes.bool,
};

Footer.defaultProps = {
  onApply: undefined,
  disableApply: false,
};

const getHeader = (header, label) => (header || `Edit ${label.toLowerCase()}`);

const OverrideText = ({
                        onChange,
                        value,
                        overrideValue,
                        label,
                        allowNegative,
                        clearButtonText,
                        clickable,
                        className,
                        clearable,
                        header,
                      }) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const [currentOverrideValue, setCurrentOverrideValue] = useState(overrideValue);
  const themeContext = useContext(ThemeContext);
  useEffect(() => setCurrentOverrideValue(overrideValue), [overrideValue]);

  const onClick = () => (clickable ? toggleIsOpen(true) : noop());

  return (
    <div className={classNames(styles.wrapper, className)}>
      {clickable && (
      <Modal
        isOpen={isOpen}
        header={getHeader(header, label)}
        onRequestClose={() => toggleIsOpen(false)}
        className={classNames({ [styles.clearableModal]: clearable })}
        footer={(
          <Footer
            disableApply={overrideValue === currentOverrideValue}
            onApply={() => {
              onChange({
                overrideValue: currentOverrideValue,
                value,
              });
              toggleIsOpen(false);
            }}
          />
        )}
      >
        <NumberInput
          label={`${label} override`}
          onChange={setCurrentOverrideValue}
          value={currentOverrideValue}
          className={styles.overrideTextInput}
          placeholder="$"
          prefix="$"
          decimalScale={2}
          allowNegative={allowNegative}
        />
        {clearable && !isNil(overrideValue) && (
          <div>
            <TertiaryButton
              className={styles.clearButton}
              onClick={() => {
                setCurrentOverrideValue(null);
                onChange({
                  overrideValue: null,
                  value,
                });
                toggleIsOpen(false);
              }}
            >
              {isFunction(clearButtonText)
                ? clearButtonText({ value, overrideValue, currentOverrideValue })
                : clearButtonText}
              <Icon name="refresh" color={themeContext.palette.information01} />
            </TertiaryButton>
          </div>
        )}
      </Modal>
      )}
      <div className={styles.label}>{label}</div>
      {!isNil(overrideValue)
        ? <WithOverride value={value} overrideValue={overrideValue} onClick={onClick} disabledClick={!clickable} />
        : <ClickableValue value={value} onClick={onClick} disabled={!clickable} />}
    </div>
  );
};

OverrideText.defaultProps = {
  onChange: () => {},
  overrideValue: undefined,
  allowNegative: true,
  clearable: false,
  clearButtonText: undefined,
  label: undefined,
  clickable: true,
  value: undefined,
  className: '',
  header: '',
};

OverrideText.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  overrideValue: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  clearButtonText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  allowNegative: PropTypes.bool,
  clickable: PropTypes.bool,
  clearable: PropTypes.bool,
  header: PropTypes.string,
};

export default OverrideText;
