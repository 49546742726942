import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Inline, PencilLg } from '@construct-kit/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Button from 'shared/components/Buttons/SecondaryButton';
import LazyLoad from 'shared/components/LazyLoad';
import styles from './TaskSection.module.scss';
import ViewableTaskLists from './ViewableTaskLists';
import TemplateListModal from './TemplateListModal';

const EditButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: space-around;
  min-width: 11rem;
  padding: 0;
`;

const AddTasksButton = styled(Button)`
  min-width: 19rem;
  padding: 0;
`;

const Container = styled(Inline)`
  margin-bottom: ${({ theme }) => theme.spacing.medium };
`;

const EditIcon = styled(PencilLg)`
  color: ${({ theme, isTaskListsEmpty }) => (isTaskListsEmpty ? theme.palette.text05 : theme.palette.text01)};
`;

const OperationBox = ({
  setEditable,
  isTaskListsEmpty,
  setTemplateListModalVisible,
}) => (
  <Container
    grow={false}
    justifyContent="space-between"
  >
    <AddTasksButton
      onClick={() => setTemplateListModalVisible(true)}
    >
      Add tasks from template
    </AddTasksButton>
    <EditButton
      onClick={() => setEditable(true)}
      disabled={isTaskListsEmpty}
    >
      Edit tasks
      <EditIcon />
    </EditButton>
  </Container>
);

OperationBox.propTypes = {
  setEditable: PropTypes.bool.isRequired,
  isTaskListsEmpty: PropTypes.bool.isRequired,
  setTemplateListModalVisible: PropTypes.bool.isRequired,
};

const renderViewableTaskListsWrapper = (
  taskLists,
  setEditable,
  setTemplateListModalVisible,
) => (
  <>
    <OperationBox
      setEditable={setEditable}
      isTaskListsEmpty={isEmpty(taskLists)}
      setTemplateListModalVisible={setTemplateListModalVisible}
    />
    <ViewableTaskLists taskLists={taskLists} />
  </>
);

const renderEditableTaskListsWrapper = (taskLists, setEditable) => (
  <LazyLoad
    loader={() => import('./EditableTaskLists/index')}
    taskLists={taskLists}
    setTaskSectionEditable={setEditable}
  />
);

const TaskSection = ({ taskLists }) => {
  const [editable, setEditable] = useState(false);
  const [templateListModalVisible, setTemplateListModalVisible] = useState(
    false,
  );
  const nextOrder = useMemo(() => taskLists.length, [taskLists.length]);
  return (
    <div className={styles.taskSection}>
      <div className={styles.taskSectionTitle}>Tasks</div>
      {editable
        ? renderEditableTaskListsWrapper(taskLists, setEditable)
        : renderViewableTaskListsWrapper(
            taskLists,
            setEditable,
            setTemplateListModalVisible,
          )}
      <TemplateListModal
        order={nextOrder}
        isOpen={templateListModalVisible}
        onClose={() => setTemplateListModalVisible(false)}
      />
    </div>
  );
};

TaskSection.propTypes = {
  taskLists: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  taskLists: get(state, 'application.tasksInfo.taskLists'),
});

export default connect(mapStateToProps)(TaskSection);
