import React, { useState, useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Icon from 'shared/components/Icon/Icon';
import Modal from 'shared/components/Modal/Modal';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import { useHasPermission } from 'shared/components/authorisation/hasPermissionTo';
import styles from './PopupForm.module.scss';

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

const PopupForm = ({
  initData, onSave,
  placeholder,
  Slot, displayText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [value, setValue] = useState(initData);
  const themeContext = useContext(ThemeContext);
  const openModal = useMemo(() => () => {
    setValue(initData);
    setIsOpen(true);
  }, [setIsOpen, initData, setValue]);
  const closeModal = useMemo(() => () => setIsOpen(false), [setIsOpen]);

  const handleKeyDown = useMemo(() => (e) => {
    [ENTER_KEY_CODE, SPACE_KEY_CODE].filter(code => code === e.keyCode).map(openModal);
  }, [openModal]);

  const handleSave = () => {
    if (initData !== value && !isEmpty(value)) {
      setIsSaving(true);
      onSave(value)
        .then(() => closeModal())
        .finally(() => setIsSaving(false));
    } else {
      closeModal();
    }
  };
  const isEditable = useHasPermission(PERMISSIONS.EDIT_ALL_APPLICATION_TRACKING);

  return (
    <>
      <div
        className={styles.contentWrapper}
        role="button"
        tabIndex={0}
        onClick={openModal}
        onKeyDown={handleKeyDown}
        data-cy="PopupForm"
      >
        {isEmpty(displayText) ? (
          <span className={styles.placeholder}>{placeholder}</span>
        ) : (
          <span className={styles.content}>{displayText}</span>
        )}
        <div className={styles.editIcon}>
          <Icon name="edit" color={themeContext.palette.information01} size="12px" />
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        header="Edit field"
        onRequestClose={closeModal}
        overlayClassName={styles.overlay}
        footer={(
          <PrimaryButton
            className={styles.saveButton}
            onClick={handleSave}
            disabled={isSaving}
            loading={isSaving}
          >
            Save
          </PrimaryButton>
        )}
      >
        <div className={styles.bodyWrapper}>
          <Slot defaultValue={value} onChange={setValue} editable={isEditable} />
        </div>
      </Modal>
    </>
  );
};

PopupForm.defaultProps = {
  initData: '',
  placeholder: '',
  displayText: '',
};

PopupForm.propTypes = {
  initData: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  Slot: PropTypes.elementType.isRequired,
  displayText: PropTypes.string,
};

export default PopupForm;
