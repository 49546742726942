import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'shared/components/Icon/Icon';
import Popover from 'shared/components/NavbarMenu/Popover';
import styles from './LeftNavBar.module.scss';
import CircleNumber from '../CircleNumber';
import TertiaryButton from '../Buttons/TertiaryButton';

const NOOP = $ => $;

const LeftNavBar = ({
  title, tabs, circleNumberMap, className, activeTab, setActiveTab,
}) => {
  const themeContext = useContext(ThemeContext);

  const trigger = toggle => (
    <div
      className={styles.navBarTitleWrapper}
      onClick={() => toggle()}
      onKeyDown={NOOP}
      role="presentation"
    >
      <h3 className={styles.navBarTitle}>{title}</h3>
      <div className={styles.navBarIcon}>
        <Icon name="menu" color={themeContext.palette.information01} />
      </div>
    </div>
  );
  return (
    <div className={classNames(styles.nav, className)}>
      <div className={styles.desktop}>
        <div className={styles.navBarTitleWrapper}>
          <h3 className={styles.navBarTitle}>{title}</h3>
          <div className={styles.navBarIcon}>
            <Icon name="menu" color={themeContext.palette.information01} />
          </div>
        </div>
        <ul className={classNames(styles.navItemWrapper)}>
          {tabs.map(val => (
            <li
              key={val}
              className={classNames(styles.navItem, { [styles.active]: val === activeTab })}
            >
              <TertiaryButton
                onClick={() => setActiveTab(val)}
                className={styles.navItemButton}
              >
                <span>
                  {val}
                </span>
                {
                      Object.keys(circleNumberMap).includes(val)
                      && circleNumberMap[val] > 0
                      && (<CircleNumber number={circleNumberMap[val]} />)
                    }
              </TertiaryButton>
            </li>
              ))}
        </ul>
      </div>
      <Popover trigger={trigger} className={styles.mobile}>
        {(toggle, isShown) => (
          <ul
            className={classNames(styles.navItemWrapper, { [styles.invisible]: !isShown })}
          >
            {tabs.map(val => (
              <li
                key={val}
                className={classNames(styles.navItem, { [styles.active]: val === activeTab })}
                onKeyDown={NOOP}
                onClick={() => toggle()}
                role="presentation"
              >
                <TertiaryButton
                  onClick={() => setActiveTab(val)}
                  className={styles.navItemButton}
                >
                  <span>
                    {val}
                  </span>
                  {
                      Object.keys(circleNumberMap).includes(val)
                      && circleNumberMap[val] > 0
                      && (<CircleNumber number={circleNumberMap[val]} />)
                    }
                </TertiaryButton>
              </li>
              ))}
          </ul>
        )}
      </Popover>

    </div>
  );
};

LeftNavBar.defaultProps = {
  circleNumberMap: {},
  className: undefined,
};

LeftNavBar.propTypes = {
  title: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActiveTab: PropTypes.func.isRequired,
  circleNumberMap: PropTypes.object,
  className: PropTypes.string,
};

export default LeftNavBar;
