import React, { PureComponent } from 'react';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import PropTypes from 'prop-types';
import ErrorMessage from 'shared/components/ErrorMessage/ErrorMessage';
import ClickableText from 'shared/components/formFields/ClickableText';
import noop from 'lodash/noop';
import { PencilLg, CopyLg } from '@construct-kit/core';
import styled from 'styled-components';
import styles from './TaskListTemplate.module.scss';

const EditIcon = styled(PencilLg)`
  color: ${({ theme }) => theme.palette.information01};
`;

const CopyIcon = styled(CopyLg)`
  color: ${({ theme }) => theme.palette.information01};
`;

const renderEmptyMask = (emptyTitle, emptyDescription) => (
  <ErrorMessage
    title={emptyTitle}
    description={emptyDescription}
  />
);

const renderActions = (template, editable, onCreateTemplate, onCopyTemplate) => (
  <>
    <div
      className={styles.actionMenu}
      role="button"
      tabIndex={0}
      onClick={() => onCopyTemplate(template)}
      onKeyDown={event => (event.key === 'Enter' ? onCopyTemplate(template) : null)}
    >
      <span>Copy</span>
      <CopyIcon />
    </div>
    {editable && (
      <div
        className={styles.actionMenu}
        role="button"
        tabIndex={0}
        onClick={() => onCreateTemplate(template)}
        onKeyDown={event => (event.key === 'Enter' ? onCreateTemplate(template) : null)}
      >
        <span>Edit</span>
        <EditIcon />
      </div>
    )}
  </>
);

const renderTaskListTemplates = (taskListTemplates, onCreateTemplate, onCopyTemplate, editable) => (
  <div className={styles.taskListTemplateContent}>
    {taskListTemplates.map(template => (
      <div key={template.id} className={styles.taskListTemplateRow}>
        <ClickableText
          className={styles.taskListTemplateName}
          text={template.name}
          onClick={() => onCreateTemplate(template)}
        />
        {renderActions(template, editable, onCreateTemplate, onCopyTemplate)}
      </div>
    ))}
  </div>
  );

class TemplateList extends PureComponent {
  render() {
    const {
      title, emptyTitle, emptyDescription, taskListTemplates, onCreateTemplate, onCopyTemplate, editable,
    } = this.props;
    return (
      <div className={styles.taskListTemplates}>
        <div className={styles.taskListTemplateTitle}>
          <h2>{title}</h2>
          { editable && (
            <PrimaryButton
              onClick={() => onCreateTemplate({})}
            >
              Create new template
            </PrimaryButton>
          )}
        </div>
        { taskListTemplates.length > 0
          ? renderTaskListTemplates(taskListTemplates, onCreateTemplate, onCopyTemplate, editable)
          : renderEmptyMask(emptyTitle, emptyDescription) }
      </div>
    );
  }
}

TemplateList.propTypes = {
  title: PropTypes.string.isRequired,
  emptyTitle: PropTypes.string,
  emptyDescription: PropTypes.string,
  taskListTemplates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })),
  })),
  onCreateTemplate: PropTypes.func,
  onCopyTemplate: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

TemplateList.defaultProps = {
  emptyTitle: '',
  emptyDescription: '',
  taskListTemplates: [],
  onCreateTemplate: noop,
  editable: false,
};

export default TemplateList;
