import React, {
 useState, useEffect, useMemo, useContext,
} from 'react';
import { ThemeContext } from 'styled-components';
import Modal from 'shared/components/Modal/ModalWithButtons';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import some from 'lodash/some';
import mapKeys from 'lodash/mapKeys';
import noop from 'lodash/noop';
import classNames from 'classnames/bind';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import RetryComponent from 'shared/components/RetryComponent';
import { useConfirmBeforeUnload } from 'shared/hooks/useConfirmBeforeUnload';
import styles from './styles.module.scss';
import { Category } from './Category';
import { FETCHING_STATUS } from '../../../../constants';
import { categoryShape } from '../shapes/categoryShape';
import { usePromise } from '../../../../shared/hooks/usePromise';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';

const cx = classNames.bind(styles);

const ENTER_KEY_CODE = 13;
const MAX_CATEGORY_NAME = 100;
const MAX_CATEGORIES_PER_BUSINESS = 15;

const validate = (value, existingValues) => {
  if (value.length > MAX_CATEGORY_NAME) {
    return 'Invalid category name, The maximum length of a category name is 100';
  }

  if (map(existingValues, 'name').includes(value)) {
    return 'Duplicate category. Please create a unique category.';
  }

  return null;
};

const getChanges = (categories, defaultCategories) => {
  const defaultCategoriesMap = mapKeys(defaultCategories, 'id');

  const categoriesToDelete = categories
    .filter((category) => !isEmpty(category.id) && category.deleted)
    .map((category) => pick(category, ['id', 'name']));

  const categoriesToUpdate = categories
    .filter(
      (category) =>
        !isEmpty(category.id)
        && !category.deleted
        && category.id in defaultCategoriesMap
        && category.name !== defaultCategoriesMap[category.id].name,
    )
    .map((category) => pick(category, ['id', 'name']));

  const categoriesToCreate = categories
    .filter((category) => isEmpty(category.id) && !category.deleted)
    .map((category) => pick(category, ['id', 'name']));

  return { categoriesToDelete, categoriesToUpdate, categoriesToCreate };
};

const isDirty = (changes) => some(changes, (payload) => !isEmpty(payload));

export const CategoriesManagementModal = ({
  defaultCategories,
  fetchingStatus,
  updatingStatus,
  onSaveCategories,
  onRequestClose,
  ...others
}) => {
  const themeContext = useContext(ThemeContext);

  const [categories, updateCategories] = useState(defaultCategories);
  const [inputName, updateInputName] = useState('');
  const [inputTouched, touchInput] = useState(false);
  const [showInput, toggleInput] = useState(false);
  const [showConfirmPopUp, toggleConfirmPopUp] = useState(false);
  const [confirm, resolveConfirm, rejectConfirm] = usePromise(
    () => () => {
      toggleConfirmPopUp(true);
      return () => toggleConfirmPopUp(false);
    },
    [toggleConfirmPopUp],
  );
  const trimmedInputName = trim(inputName);

  const error = validate(trimmedInputName, categories);

  const changes = useMemo(() => getChanges(categories, defaultCategories), [
    categories,
    defaultCategories,
  ]);
  const dirty = isDirty(changes);
  const { prompt, confirmWhenDirty } = useConfirmBeforeUnload(dirty);

  useEffect(() => {
    if (fetchingStatus === FETCHING_STATUS.SUCCESS) {
      updateCategories(defaultCategories);
    }
  }, [updateCategories, fetchingStatus, defaultCategories]);
  const handleDeleteCategory = useMemo(
    () => (categoryToDelete) => {
      updateCategories(
        categories.map((category) =>
          (category === categoryToDelete
            ? { ...categoryToDelete, deleted: true }
            : category)),
      );
    },
    [updateCategories, categories],
  );

  const handleRevertDeleteCategory = useMemo(
    () => (categoryToRevert) => {
      updateCategories(
        categories.map((category) =>
          (category === categoryToRevert
            ? { ...categoryToRevert, deleted: false }
            : category)),
      );
    },
    [updateCategories, categories],
  );

  const validCategoriesCount = categories.filter(
    (category) => !category.deleted,
  ).length;

  const reachMaxCategoriesCount = validCategoriesCount >= MAX_CATEGORIES_PER_BUSINESS;

  const overMaxCategoriesCount = validCategoriesCount > MAX_CATEGORIES_PER_BUSINESS;

  const updating = updatingStatus === FETCHING_STATUS.START;

  const disableAdd = showInput || reachMaxCategoriesCount || updating;

  const handleClickAdd = useMemo(
    () => () => {
      toggleInput(true);
    },
    [toggleInput],
  );

  const handleEnter = useMemo(
    () => () => {
      if (!error) {
        updateInputName('');
        toggleInput(false);
        touchInput(false);
        if (!isEmpty(trimmedInputName)) {
          updateCategories([...categories, { name: trimmedInputName }]);
        }
      } else {
        touchInput(true);
      }
    },
    [error, trimmedInputName, categories],
  );

  const handleKeyDown = useMemo(
    () => (e) => {
      if (e.keyCode !== ENTER_KEY_CODE) {
        return;
      }
      handleEnter();
    },
    [handleEnter],
  );

  const handleClickSave = useMemo(
    () => async () => {
      try {
        if (!isEmpty(changes.categoriesToDelete)) {
          await confirm();
        }
        onSaveCategories(changes);
      } catch {
        noop();
      }
    },
    [onSaveCategories, changes, confirm],
  );

  const handleRequestClose = useMemo(
    () => () => {
      /* eslint-disable no-alert */
      if (!updating && confirmWhenDirty()) {
        onRequestClose();
      }
      /* eslint-enable no-alert */
    },
    [confirmWhenDirty, onRequestClose, updating],
  );

  const handleInputChanged = useMemo(
    () => (v) => {
      updateInputName(v);
      if (!inputTouched) {
        touchInput(true);
      }
    },
    [inputTouched, touchInput],
  );
  return (
    <Modal
      overlayClassName={cx({ hiddenOverlay: showConfirmPopUp, overlay: true })}
      submitText="Save"
      header="Manage categories"
      submitButtonProps={{
        onClick: handleClickSave,
        loading: updatingStatus === FETCHING_STATUS.START,
        disabled:
          updating
          || fetchingStatus !== FETCHING_STATUS.SUCCESS
          || (inputTouched && error)
          || overMaxCategoriesCount,
      }}
      className={styles.modal}
      disabled={updating}
      onRequestClose={handleRequestClose}
      {...others}
    >
      <ConfirmDeleteModal
        isOpen={showConfirmPopUp}
        onRequestClose={rejectConfirm}
        onConfirmed={resolveConfirm}
      />
      {prompt}
      {fetchingStatus === FETCHING_STATUS.START && (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      )}
      {fetchingStatus === FETCHING_STATUS.ERROR && (
        <RetryComponent className={styles.retryMessage} />
      )}
      {fetchingStatus === FETCHING_STATUS.SUCCESS && (
        <div className={styles.content}>
          <div className={styles.title}>
            Deleting categories will delete them from the categories dropdown
            even when they have been applied against a loan.
          </div>
          <div className={styles.categories}>
            {categories.map((category) => (
              <Category
                key={`${category.id}-${category.name}`}
                disabled={updating}
                category={category}
                onDelete={handleDeleteCategory}
                onRevertDelete={handleRevertDeleteCategory}
              />
            ))}
            {showInput && (
              <TextInput
                className={styles.input}
                value={inputName}
                placeholder="Type to create category"
                onChange={handleInputChanged}
                maxLength={100}
                onKeyDown={handleKeyDown}
                onBlur={handleEnter}
                autoFocus
              />
            )}
            {error && inputTouched && (
              <div className={styles.errorMessage}>{error}</div>
            )}
            {isEmpty(categories) && !showInput && (
              <div className={styles.noDateMessage}>No existing categories</div>
            )}
          </div>

          <div className={styles.addBtnContainer}>
            <SecondaryButton
              className={styles.addBtn}
              disabled={disableAdd}
              onClick={disableAdd ? null : handleClickAdd}
              onKeyDown={handleKeyDown}
            >
              <Icon
                name="add"
                color={
                  disableAdd ? themeContext.palette.text05 : themeContext.palette.text01
                }
                size="18"
              />
              <div>Add</div>
            </SecondaryButton>
            <div
              className={cx({ hint: true, errHint: overMaxCategoriesCount })}
            >
              Maximum of 15 loan categories
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

CategoriesManagementModal.propTypes = {
  defaultCategories: PropTypes.arrayOf(categoryShape).isRequired,
  fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  updatingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  onSaveCategories: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};
