import { useQuery } from '@apollo/client';
import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FIELD_SIDE_BAR_COLORS } from 'shared/components/Field/Field';
import sortBy from 'lodash/sortBy';
import findLast from 'lodash/findLast';
import some from 'lodash/some';
import includes from 'lodash/includes';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { toRatePercentage } from 'shared/formatterUtils';
import LoanCriteria from 'shared/components/LoanDetails/LoanCriteria';
import LoanExtraInfo from 'shared/components/LoanDetails/LoanExtraInfo';
import ExternalLink from 'shared/components/Links/ExternalLink';
import get from 'lodash/get';
import stylePropType from 'react-style-proptype';
import { EditField, USER_PERMISSIONS } from 'Loans/components/LoanDetails/EditField';
import EditProductName from 'Loans/components/LoanDetails/LoanDetailsContent/EditProduct/EditProduct';
import loanExtraInfoStyles from 'shared/components/LoanDetails/LoanExtraInfo/LoanExtraInfo.module.scss';
import Icon from 'shared/components/Icon/Icon';
import InputApprovalNumber from '../inputs/InputApprovalNumber';
import LoanShape from '../../../../shapes/LoanShape';
import LoanLinks from './LoanLinks';
import { InputPercentage } from '../inputs/InputPercentage';
import { selectLoanType } from '../../redux/loanActions';
import { getLenderName, getLoanType } from '../../../../selectors/loanSelector';
import { PRODUCT_TYPES } from '../../../../../constants';
import { GET_AUDIT_TRAILS } from '../../AuditTrail/auditTrailGraphQL';
import RetryComponent from '../../../../../shared/components/RetryComponent';
import LoadingSpinner from '../../../../../shared/components/LoadingSpinner/LoadingSpinner';
import { toDateWithSlash } from '../../../../../utils/datetime';
import { filterAuditChange } from '../../../../utils';

const INTEREST_RATE_FIELDS = ['clientDiscountRate', 'lastKnownRate', 'productBaseRate'];

const getIdentifiers = loan => [
  {
    title: 'Approval number',
    content: <InputApprovalNumber />,
  },
  {
    title: 'Account number',
    content: () => loan.accountNumber,
  },
];

const getAuditEvents = ({ loan }) => {
  const {
    loading, error, data, refetch, networkStatus,
  } = useQuery(GET_AUDIT_TRAILS, {
    variables: { loanId: loan.id },
  });
  useEffect(() => { refetch(); }, [refetch, loan]);

  if (error) {
    return <RetryComponent style={{ marginTop: '0' }} />;
  }
  if (loading || networkStatus === 4) {
    return <LoadingSpinner />;
  }
  return data.auditTrail;
};

const getNetRate = (isNetRateUpdateBefore, lastKnowRate, standardRate) =>
  (isNetRateUpdateBefore || lastKnowRate ? lastKnowRate : standardRate);

const getInterestRates = (
  loan,
  isNetRateUpdateBefore,
  setDiscountRateTemp,
  setNetRateTemp,
  setIsInterestRateAlertActive,
) =>
  [
    {
      title: 'Approved discount',
      content: (
        <EditField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={toRatePercentage(loan.clientDiscount, '')}
        >
          <InputPercentage
            name="clientDiscountRate"
            value={loan.clientDiscount}
            onBlur={e => {
              setDiscountRateTemp(parseFloat(e.target.value));
              setIsInterestRateAlertActive(true);
            }}
          />
        </EditField>
      ),
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
    },
    {
      title: 'Net rate',
      content: (
        <EditField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={toRatePercentage(getNetRate(isNetRateUpdateBefore, loan.lastKnownRate, loan.standardRate), '')}
        >
          <InputPercentage
            name="lastKnownRate"
            value={getNetRate(isNetRateUpdateBefore, loan.lastKnownRate, loan.standardRate)}
            onBlur={e => {
              setNetRateTemp(parseFloat(e.target.value));
              setIsInterestRateAlertActive(true);
            }}
          />
        </EditField>
      ),
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
    },
  ];

const getIntroductoryInterestRates = (loan) =>
  [
    {
      title: 'Approved discount',
      content: 'N/A',
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
    },
    {
      title: 'Net rate',
      content: (
        <EditField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={toRatePercentage(loan.discountRate, '')}
        >
          <InputPercentage
            name="discountRate"
            value={loan.discountRate}
          />
        </EditField>
      ),
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
    },
  ];

const filterInterestRateUpdatedEvents = (auditEvents, standardRate) =>
  filterAuditChange(auditEvents, standardRate)
    .filter(event => some(event.changes, change => includes(INTEREST_RATE_FIELDS, change.fieldName)) && { type: 'LOAN_UPDATED' });

const getUpdateEvent = (auditEvents, standardRate) => {
  const updatedEvents = filterInterestRateUpdatedEvents(
    auditEvents,
    standardRate ? String(standardRate) : standardRate,
  );
  const updatedEventsSorted = sortBy(updatedEvents, ['actionTime']);
  return {
    updateEvent: findLast(updatedEventsSorted),
    isNetRateUpdateBefore: !auditEvents || containsNetRateUpdateEvent(updatedEventsSorted),
  };
};

const containsNetRateUpdateEvent = (updatedEventsSorted) =>
  updatedEventsSorted.flatMap(event => event.changes).some(change => change.fieldName === 'lastKnownRate');

const getInterests = (
  loan, setBaseRateTemp, setDiscountRateTemp, setNetRateTemp, setIsInterestRateAlertActive, showAlert,
) => {
  const themeContext = useContext(ThemeContext);

  const auditEvents = getAuditEvents({ loan });
  const { updateEvent, isNetRateUpdateBefore } = getUpdateEvent(auditEvents.auditEvents, loan.standardRate);
  const baseRate = {
    title: loan.productType === PRODUCT_TYPES.fixed ? 'Fixed rate' : 'Base rate',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
        fallback={toRatePercentage(loan.baseRate, '')}
      >
        <InputPercentage
          name="baseRate"
          value={loan.baseRate}
          onBlur={e => {
            setBaseRateTemp(parseFloat(e.target.value));
            setIsInterestRateAlertActive(true);
          }}
        />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
  };
  const interestRates = getInterestRates(
    loan,
    isNetRateUpdateBefore,
    setDiscountRateTemp,
    setNetRateTemp,
    setIsInterestRateAlertActive,
  );
  const updated = {
    title: 'Updated',
    content: toDateWithSlash(get(updateEvent, 'actionTime', '')),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
  };
  const description = {
    key: 'description',
    title: '',
    showSideBar: false,
    content: (
      <p>Net rate is calculated by subtracting approved discount from {baseRate.title.toLowerCase()}.
        <ExternalLink to="https://smartline.zendesk.com/hc/en-us/articles/4405219976079"> Find out more.</ExternalLink>
      </p>
    ),
    className: loanExtraInfoStyles.interestRateDescription,
  };
  const alert = {
    key: 'alert',
    title: '',
    content: (
      <div className={loanExtraInfoStyles.interestRateAlertDetail}>
        <Icon
          name="info"
          size="20px"
          color={themeContext.palette.text02}
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '10px',
          }}
        />
        <span>{loan.discountRate ? 'Revert interest rate' : 'Interest rate'} fields don’t add up. Check the numbers you have entered are correct.</span>
      </div>
    ),
    showSideBar: false,
    className: loanExtraInfoStyles.interestRateDescription,
    noDisplay: !showAlert,
  };
  return [baseRate, ...interestRates, updated, alert, description];
};

const getIntroductoryInterests = (loan) => {
  const baseRate = {
    title: loan.productType === PRODUCT_TYPES.fixed ? 'Fixed rate' : 'Base rate',
    content: 'N/A',
    sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
  };
  const introductoryInterestRates = getIntroductoryInterestRates(loan);
  return [baseRate, ...introductoryInterestRates];
};

const Loan = ({
                loan, style, isEditing, dispatchSelectLoanType,
                selectedLoanType, selectedLenderName,
              }) => {
  useEffect(() => {
    dispatchSelectLoanType(loan.loanType);
  }, [dispatchSelectLoanType, loan, isEditing]);
  const [baseRateTemp, setBaseRateTemp] = useState(loan.baseRate);
  const [discountRateTemp, setDiscountRateTemp] = useState(loan.clientDiscount);
  const [netRateTemp, setNetRateTemp] = useState(loan.lastKnownRate ?? loan.standardRate);
  const [isInterestRateAlertActive, setIsInterestRateAlertActive] = useState(false);

  useEffect(() => {
    if (!isEditing) {
      setBaseRateTemp(loan.baseRate);
      setDiscountRateTemp(loan.clientDiscount);
      setNetRateTemp(loan.lastKnownRate ?? loan.standardRate);
      setIsInterestRateAlertActive(false);
    }
  }, [loan, isEditing]);
  const showAlert = isInterestRateAlertActive
    && Number((baseRateTemp - discountRateTemp).toFixed(2)) !== netRateTemp;

  const defaultProductName = loan.brokerPlatformId ? 'Loan product not captured' : null;
  return (
    <div style={style}>
      <InformationSection title={loan.productName ?? defaultProductName}>
        {isEditing && (
        <EditProductName
          productName={loan.productName}
          loanType={selectedLoanType}
          lenderName={selectedLenderName}
        />
        )}
        <LoanCriteria
          loan={loan}
          isEditing={isEditing}
          onLoanTypeChange={dispatchSelectLoanType}
        />
        <LoanExtraInfo
          interestFields={getInterests(
            loan, setBaseRateTemp, setDiscountRateTemp, setNetRateTemp, setIsInterestRateAlertActive, showAlert,
          )}
          introductoryInterestFields={getIntroductoryInterests(loan)}
          identifierFields={getIdentifiers(loan)}
          introductoryInterestTerm={loan.discountRateTerm}
        />
      </InformationSection>
      <LoanLinks
        refinancedFrom={loan.refinancedFrom}
        refinancedTo={loan.refinancedTo}
        applicationId={loan.applicationId}
        splitLoans={loan.splitLoans}
        currentLoanId={loan.id}
        brokerPlatformId={loan.brokerPlatformId}
      />
    </div>
  );
};

Loan.defaultProps = {
  style: {},
  selectedLenderName: undefined,
};

Loan.propTypes = {
  loan: LoanShape.isRequired,
  style: stylePropType,
  isEditing: PropTypes.bool.isRequired,
  dispatchSelectLoanType: PropTypes.func.isRequired,
  selectedLoanType: PropTypes.string.isRequired,
  selectedLenderName: PropTypes.string,
};

const mapStateToProps = state => ({
  loan: get(state, 'loans.loan.data'),
  isEditing: get(state, 'loans.loan.isEditing'),
  selectedLoanType: getLoanType(state),
  selectedLenderName: getLenderName(state),
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectLoanType: selectedLoanType => dispatch(selectLoanType(selectedLoanType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Loan);
