import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import HtmlEditor, { Item, Toolbar } from 'devextreme-react/html-editor';
import isEmpty from 'lodash/isEmpty';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import Modal from 'shared/components/Modal';
import styles from '../../ApplicationDetails.module.scss';
import 'devextreme/dist/css/dx.light.compact.css';

const isEmptyContent = (htmlString) =>
  isEmpty(
    new DOMParser().parseFromString(htmlString, 'text/html').documentElement
      .textContent,
  );

class AddNote extends Component {
  constructor(props) {
    super(props);
    this.sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    this.fontValues = [
      'Arial',
      'Courier New',
      'Georgia',
      'Impact',
      'Lucida Console',
      'Tahoma',
      'Times New Roman',
      'Verdana',
    ];
    this.editor = React.createRef();
    const { theme } = this.props;
    this.state = {
      isCreateNoteModalOpen: false,
      noteContent: '',
      isSavable: false,
      isSaving: false,
      constructTheme: theme,
    };
  }

  handleAddNote = () => {
    this.setState(
      {
        isCreateNoteModalOpen: true,
      },
      () =>
        setTimeout(
          () => this.editor.current && this.editor.current.instance.focus(),
          100,
        ),
    );
  };

  handleAddNoteClose = () => {
    this.setState({
      isCreateNoteModalOpen: false,
      noteContent: '',
      isSavable: false,
    });
  };

  noteContentChanged = (e) => {
    this.setState({
      noteContent: e.value,
      isSavable: !isEmptyContent(e.value),
    });
  };

  handleSaveNote = () => {
    const { saveNote } = this.props;
    const { noteContent } = this.state;
    this.setState({
      isSaving: true,
    });
    saveNote(noteContent)
      .then(() => {
        this.handleAddNoteClose();
        this.setState({
          isSaving: false,
        });
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  renderEditor() {
    return (
      <HtmlEditor onValueChanged={this.noteContentChanged} ref={this.editor}>
        <Toolbar>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item name="size" acceptedValues={this.sizeValues} />
          <Item name="font" acceptedValues={this.fontValues} />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
        </Toolbar>
      </HtmlEditor>
    );
  }

  render() {
    const {
      isCreateNoteModalOpen,
      isSavable,
      isSaving,
      constructTheme,
    } = this.state;
    return (
      <div>
        <SecondaryButton
          className={styles.addNoteButton}
          onClick={this.handleAddNote}
        >
          <span className={styles.noteButtonText}>Add note</span>
          <Icon name="note" color={constructTheme.palette.text01} size="18px" />
        </SecondaryButton>
        <Modal
          className={styles.applicationNewNoteModal}
          isOpen={isCreateNoteModalOpen}
          onRequestClose={this.handleAddNoteClose}
          header={<h3 className={styles.newNoteModalTitle}>New note</h3>}
          footer={(
            <PrimaryButton
              className={styles.saveNoteButton}
              onClick={this.handleSaveNote}
              disabled={!isSavable || isSaving}
            >
              Save
            </PrimaryButton>
          )}
        >
          <div className={styles.applicationNoteEditor}>
            {this.renderEditor()}
          </div>
        </Modal>
      </div>
    );
  }
}

AddNote.propTypes = {
  saveNote: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(AddNote);
