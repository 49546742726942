import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import { checkIfKeyIsEnterOrSpace } from '../../../../utils';

// copy from https://github.com/DevExpress/devextreme-reactive/blob/086242d19f1605b05fed10c096bcf570f81325bc/packages/dx-react-grid-bootstrap4/src/templates/table-header-cell/sort-label.jsx

const onClick = (e, onSort) => {
  const isActionKeyDown = checkIfKeyIsEnterOrSpace(e);
  const isMouseClick = e.keyCode === undefined;

  if (!(isActionKeyDown || isMouseClick)) return;

  const cancelSortingRelatedKey = e.metaKey || e.ctrlKey;
  const direction = (isMouseClick || isActionKeyDown) && cancelSortingRelatedKey
    ? null
    : undefined;
  const keepOther = e.shiftKey || cancelSortingRelatedKey;

  e.preventDefault();
  onSort({ direction, keepOther });
};

const getStyleFromDirection = (direction, colorPalette) => {
  switch (direction) {
    case 'asc':
      return {
        icon: 'arrow-up-solid',
        iconColor: colorPalette.information01,
        color: colorPalette.information01,
      };
    case 'desc':
      return {
        icon: 'arrow-down-solid',
        iconColor: colorPalette.information01,
        color: colorPalette.information01,
      };
    default:
      return {
        icon: 'arrow-up-solid',
        iconColor: colorPalette.ui04,
        color: colorPalette.text01,
      };
  }
};

const SortLabel = ({
 onSort, children, direction, align, disabled,
}) => {
  const themeContext = useContext(ThemeContext);
  const { icon, iconColor, color } = getStyleFromDirection(direction, themeContext.palette);
  return (
    <TertiaryButton
      className="sortingButton"
      style={{ color, textAlign: align }}
      onClick={e => onClick(e, onSort)}
      disabled={disabled}
      icon={disabled ? null : icon}
      iconColor={iconColor}
    >
      {children}
    </TertiaryButton>
  );
};

SortLabel.propTypes = {
  children: PropTypes.node,
  onSort: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['asc', 'desc']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  disabled: PropTypes.bool,
};

SortLabel.defaultProps = {
  direction: null,
  children: undefined,
  align: 'left',
  disabled: false,
};

export default SortLabel;
