import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { toTimeStrWithoutDate, toDateStrWithoutYear, toYear } from 'utils/datetime';
import Icon from '../Icon/Icon';
import DialogBubble from '../DialogBubble/DialogBubble';
import styles from './Item.module.scss';

const Item = ({
 iconName, actionTime, operator, children, lastModifiedTime, lastModifiedByName, hasLastModify,
}) => {
  const themeContext = useContext(ThemeContext);

  const lastModifySurfix = hasLastModify ? (`・ Last edited at ${toTimeStrWithoutDate(lastModifiedTime)}, ${toDateStrWithoutYear(lastModifiedTime)}
                   ${toYear(lastModifiedTime)} by ${lastModifiedByName}`) : '';
  return (
    <div className={styles.logWrapper} data-cy="data-cy-log-wrapper">
      <div className={`${styles.left} log_wrapper_item_left`}>
        <div className={styles.iconWrapper}>
          <Icon
            name={iconName}
            size="2rem"
            color={themeContext.palette.text01}
          />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.title}>
          <span>{`${toTimeStrWithoutDate(actionTime)} by ${operator}`}</span>
          <span className={styles.lastModify}>{`${lastModifySurfix}`}</span>
        </div>
        <DialogBubble>
          {children}
        </DialogBubble>
      </div>
    </div>
  );
};

Item.defaultProps = {
  lastModifiedTime: '',
  lastModifiedByName: '',
  hasLastModify: false,
};

Item.propTypes = {
  iconName: PropTypes.string.isRequired,
  actionTime: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hasLastModify: PropTypes.bool,
  lastModifiedTime: PropTypes.string,
  lastModifiedByName: PropTypes.string,
};

export default Item;
