import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import DateBox from 'devextreme-react/date-box';
import noop from 'lodash/noop';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import {
  applicationStatus as applicationStatusMap,
  essentialDateDisplayStatus,
  reasonMap,
} from 'ApplicationTracking/constants/applicationStatus';
import { toTimeStr } from 'utils/datetime';
import Icon from 'shared/components/Icon/Icon';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import classNames from 'classnames';
import styles from '../EssentialDates.module.scss';
import useMediaType from '../../../../../../shared/hooks/useMediaType';
import { MEDIA_TYPES } from '../../../../../../constants/media';

const { PRE_SUBMISSION, DECLINED, WITHDRAWN } = applicationStatusMap;

const statusNameMapper = {
  [PRE_SUBMISSION]: 'Application created',
};

const isDeclinedOrWithdrawn = (status) =>
  isEqual(status, DECLINED) || isEqual(status, WITHDRAWN);

const ReasonArea = ({
 reason, editable, disabled, onEditClick,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <div className={styles.reasonArea}>
      <span className={styles.reasonTitle}>Reason</span>
      <div className={styles.reasonDetail}>
        <span>{reason}</span>
        {editable && (
          <span
            onClick={disabled ? undefined : onEditClick}
            className={styles.editReason}
            onKeyDown={noop}
            role="button"
            tabIndex="0"
          >
            <Icon
              name="edit"
              color={disabled ? themeContext.palette.text05 : themeContext.palette.information01}
              size="16"
            />
          </span>
        )}
      </div>
    </div>
  );
};

ReasonArea.defaultProps = {
  reason: '',
};

ReasonArea.propTypes = {
  reason: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

const getStatusName = (statusKey, mediaType) => {
  const statusName = get(
    statusNameMapper,
    statusKey,
    essentialDateDisplayStatus[statusKey],
  );
  if (mediaType === MEDIA_TYPES.DESKTOP) {
    return statusKey === applicationStatusMap.SETTLED
      ? 'Settlement'
      : statusName;
  }
  return <h3>{statusName}</h3>;
};

const StatusRow = ({
  statusKey,
  reason,
  editable,
  statusDateTime,
  applyOnlineStatusDateTime,
  onUpdateStatusDateTime,
  onUpdateReason,
  onSettle,
  disabled,
}) => {
  const [isUpdating, changeIsUpdating] = useState(false);
  const mediaType = useMediaType();
  const statusName = getStatusName(statusKey, mediaType);

  const onValueChanged = ({
 value, component, previousValue, event,
}) => {
    if (!event) {
      return;
    }
    const notChanged = isEqual(value, statusDateTime);
    if (notChanged) {
      return;
    }
    setTimeout(async () => {
      changeIsUpdating(true);
      try {
        await onUpdateStatusDateTime(statusKey, {
          reason,
          statusDateTime: value,
        });
      } catch {
        component.dateValue(previousValue);
      }
      changeIsUpdating(false);
    }, 0);
  };

  const onEditClick = () => {
    onUpdateReason(statusKey, { reason, statusDateTime });
  };

  const isIphoneOrIpodOrIpad = navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/iPad/i);

  const triggerByBlurConfig = isIphoneOrIpodOrIpad
    ? { valueChangeEvent: 'blur' }
    : { valueChangeEvent: 'change' };

  const renderStatusManualCellContent = () => {
    if (statusKey === PRE_SUBMISSION) {
      if (statusDateTime) {
        return <div>{toTimeStr(statusDateTime)}</div>;
      }
      return <div>N/A</div>;
    }

    const shouldDisplaySettleButton = editable
      && statusKey === applicationStatusMap.SETTLED
      && isNil(statusDateTime);
    if (shouldDisplaySettleButton) {
      return (
        <PrimaryButton className={styles.settlementButton} onClick={onSettle}>
          Settle application
        </PrimaryButton>
      );
    }

    return (
      <DateBox
        defaultValue={statusDateTime}
        displayFormat="d MMMM yyyy h:mm a"
        type="datetime"
        applyButtonText="Save"
        placeholder="Select a date and a time"
        invalidDateMessage="Please select a date and a time"
        useMaskBehavior
        validationMessageMode="always"
        disabled={!editable || disabled}
        width="100%"
        onValueChanged={onValueChanged}
        {...triggerByBlurConfig}
      />
    );
  };
  return (
    <div className={styles.essentialStatus} key={statusKey}>
      <div className={styles.statusStageCell}>
        <div
          className={classNames(
            styles.statusStageCellContent,
            styles.statusName,
          )}
        >
          {statusName}
        </div>
        {isDeclinedOrWithdrawn(statusKey) && statusDateTime && (
          <div
            className={classNames(
              styles.statusStageCellContent,
              styles.reasonContainer,
            )}
          >
            <ReasonArea
              disabled={isUpdating}
              editable={editable}
              reason={get(reasonMap, [statusKey, reason], 'N/A')}
              onEditClick={onEditClick}
            />
          </div>
        )}
      </div>
      <div className={styles.statusManualCell}>
        <div className={styles.statusManualCellTitle}>
          Manual processing date
        </div>
        <div className={styles.statusManualCellContent}>
          {renderStatusManualCellContent()}
        </div>
      </div>
      <div className={styles.statusApplyCell}>
        <div className={styles.statusApplyCellTitle}>ApplyOnline</div>
        <div className={styles.statusApplyCellContent}>
          {applyOnlineStatusDateTime}
        </div>
      </div>
    </div>
  );
};

StatusRow.propTypes = {
  statusKey: PropTypes.string.isRequired,
  reason: PropTypes.string,
  onUpdateStatusDateTime: PropTypes.func.isRequired,
  onUpdateReason: PropTypes.func.isRequired,
  onSettle: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  statusDateTime: PropTypes.any,
  applyOnlineStatusDateTime: PropTypes.string,
};

StatusRow.defaultProps = {
  reason: undefined,
  statusDateTime: undefined,
  applyOnlineStatusDateTime: undefined,
};

export default StatusRow;
