import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import { FONTS, MARGIN } from 'shared/theme/depricatedStyles';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import Fonts from 'shared/theme/depricatedStyles/Fonts';
import noop from 'lodash/noop';

const extractValue = (selectedValue) => selectedValue.value;
const toSelectValue = (value) => ({
  label: value,
  value,
});

const styles = {
  subLabel: {
    fontSize: '1.3rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const HEIGHT = '3rem';
const selectStyles = (hasError, palette) => ({
  menu: (defaultStyles, props) => {
    if (!props.selectProps.inputValue) {
      return {
        ...defaultStyles,
        display: 'none',
      };
    }
    return {
      ...defaultStyles,
      fontFamily: FONTS.standard,
    };
  },
  multiValueRemove: (defaultStyles) => ({
    ...defaultStyles,
    color: palette.text03,
    borderRadius: '0 5px 3px 0',
    backgroundColor: palette.focus01,
    '&:hover': {
      backgroundColor: palette.hover03,
      color: palette.text03,
    },
  }),
  multiValueLabel: (defaultStyles) => ({
    ...defaultStyles,
    color: palette.text03,
    borderRadius: '5px 0 0 5px',
    backgroundColor: palette.focus01,
    fontFamily: FONTS.standard,
    fontSize: Fonts.size.regular,
  }),
  control: (defaultStyles) => {
    let borderColor = palette.ui01;
    if (hasError) {
      borderColor = palette.error01;
    }
    return {
      ...defaultStyles,
      fontFamily: FONTS.standard,
      fontSize: FONTS.size.regular,
      minHeight: HEIGHT,
      backgroundColor: palette.background03,
      borderStyle: 'solid',
      borderWidth: '0.05rem',
      borderColor,
      borderRadius: '0.2rem',
      marginTop: MARGIN.small,
    };
  },
  option: (defaultStyles, props) => ({
    ...defaultStyles,
    backgroundColor: props.isFocused ? palette.focus02 : palette.background03,
    fontSize: FONTS.size.regular,
    colours: props.isFocused ? palette.background06 : palette.background05,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
});

const MultiSelect = ({
  label,
  subLabel,
  placeholder,
  name,
  classPrefix,
  buttonLabel,
  buttonOnClick,
  valuesSet,
  selectedValues,
  fixedValues,
  onChange,
  hasError,
  disabled,
}) => {
  const themeContext = useContext(ThemeContext);
  const selectedOptions = map(selectedValues, toSelectValue);
  const valuesOptions = sortBy(valuesSet)
    .filter((value) => fixedValues.indexOf(value) < 0)
    .map((value) => toSelectValue(value));
  const errorLabel = `The ${name} are not available at the moment.`;
  return (
    <div className="multi-select">
      <div style={styles.header}>
        <div>
          <span>{label}</span>
          <span style={styles.subLabel}>{subLabel}</span>
        </div>
        {buttonLabel && (
          <TertiaryButton onClick={buttonOnClick}>{buttonLabel}</TertiaryButton>
        )}
      </div>
      <Select
        isMulti
        placeholder={placeholder}
        name={name}
        styles={selectStyles(hasError, themeContext.palette)}
        onChange={(selectedOptionsList) => {
          const selectedList = (selectedOptionsList || []).map(extractValue);
          onChange(selectedList);
        }}
        openMenuOnClick={false}
        classNamePrefix={classPrefix}
        value={selectedOptions}
        options={valuesOptions}
        maxMenuHeight={164}
        isDisabled={disabled}
      />
      {hasError && (
        <div
          style={{
            color: themeContext.palette.error01,
            fontFamily: FONTS.standard,
            marginTop: MARGIN.small,
          }}
        >
          {errorLabel}
        </div>
      )}
    </div>
  );
};

MultiSelect.defaultProps = {
  subLabel: null,
  valuesSet: [],
  selectedValues: [],
  fixedValues: [],
  hasError: false,
  buttonLabel: null,
  buttonOnClick: noop(),
  classPrefix: null,
  disabled: false,
};

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.element,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  classPrefix: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  valuesSet: PropTypes.arrayOf(PropTypes.string),
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  fixedValues: PropTypes.arrayOf(PropTypes.string),
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default MultiSelect;
