import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import InternalLink from '../Links/InternalLink';
import styles from './BackButton.module.scss';
import Icon from '../Icon/Icon';

const BackButton = ({ text, to }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <InternalLink to={to} className={styles.backLink}>
      <Icon size="20" name="arrow-left" color={themeContext.palette.link01} />
      <span className={styles.text}>{text}</span>
    </InternalLink>
  );
};

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
export default BackButton;
