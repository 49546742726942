import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import Icon from 'shared/components/Icon/Icon';
import sizes from 'shared/theme/sizes.scss';
import { openCommissionQueryModal } from './commissionQueryRedux';

export const CommissionQuery = ({
  issueId,
  dispatchOpenCommissionQueryModal,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <>
      <TertiaryButton onClick={() => dispatchOpenCommissionQueryModal(issueId)}>
        <Icon
          name="flag"
          size={sizes.large}
          color={themeContext.palette.information01}
        />
      </TertiaryButton>
    </>
  );
};

CommissionQuery.propTypes = {
  issueId: PropTypes.string.isRequired,
  dispatchOpenCommissionQueryModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchOpenCommissionQueryModal: (issueId) =>
    dispatch(openCommissionQueryModal(issueId)),
});

export default connect(null, mapDispatchToProps)(CommissionQuery);
