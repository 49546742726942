import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Icon from 'shared/components/Icon/Icon';
import Popover from 'shared/components/NavbarMenu/Popover';
import noop from 'lodash/noop';
import styles from './NewLeftRouterBar.module.scss';
import CircleNumber from '../CircleNumber';
import ShowMore from '../ShowMore/ShowMore';

const NavItem = props => {
  const {
 path, label, isActive, count, action,
} = props;
  return (
    <div className={classNames(styles.itemWrap, { [styles.active]: isActive })}>
      <div className={styles.placeHolder} />
      <div
        key={label}
        className={styles.navItem}
      >
        <HashLink
          scroll={(el) => el.scrollIntoView({ block: 'nearest', behavior: 'smooth' })}
          to={`${path}#top`}
          className={styles.navItemLink}
          onClick={() => action()}
        >
          <span>
            {label}
          </span>
          {count > 0 && (<CircleNumber number={count} />)}
        </HashLink>
      </div>
    </div>
  );
};

NavItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  count: PropTypes.number,
  action: PropTypes.func,
};

NavItem.defaultProps = {
  count: 0,
  action: $ => $,
};

const NavSections = props => {
  const {
 sections, activePath, pathPrefix, circleNumberMap, itemAction,
} = props;
  const transformItemProps = item => ({
      ...item,
      path: `${pathPrefix}/${item.path}`,
      isActive: item.path === activePath,
      count: circleNumberMap ? circleNumberMap[item.key] : 0,
      action: itemAction,
  });

  return sections.map(section => {
    if (section.children) {
      const { name, children } = section;
      return (
        <li key={name} className={styles.section}>
          <ShowMore name={name} headerClassName={styles.tabHeader}>
            <div className={styles.children}>
              {children.map(item => <NavItem {...transformItemProps(item)} />)}
            </div>
          </ShowMore>
        </li>
      );
    }
    return (
      <li className={styles.section} key={section.key}>
        <div>
          <NavItem {...transformItemProps(section)} />
        </div>
      </li>
    );
  });
};

NavSections.propTypes = {
  sections: PropTypes.array.isRequired,
  activePath: PropTypes.string,
  pathPrefix: PropTypes.string.isRequired,
  circleNumberMap: PropTypes.object,
  itemAction: PropTypes.func,
};

NavSections.defaultProps = {
  activePath: '',
  circleNumberMap: {},
};

const NewLeftRouterBar = ({
                      title, circleNumberMap, className, match, sections,
                    }) => {
  const themeContext = useContext(ThemeContext);
  const activePath = get(match, 'params.activeTab');
  const applicationId = get(match, 'params.applicationId');
  const trigger = toggle => (
    <div
      className={styles.navBarTitleWrapper}
      onClick={() => toggle()}
      onKeyDown={noop}
      role="presentation"
    >
      <h3 className={styles.navBarTitle}>{title}</h3>
      <div className={styles.navBarIcon}>
        <Icon name="menu" color={themeContext.palette.information01} />
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.nav, className)}>
      <div className={styles.desktop} id="top">
        <div className={styles.navBarTitleWrapper}>
          <div className={styles.navBarIcon}>
            <Icon name="menu" color={themeContext.palette.information01} />
          </div>
        </div>
        <ul className={classNames(styles.navItemsWrap)}>
          <NavSections
            sections={sections}
            activePath={activePath}
            pathPrefix={`/applications/${applicationId}`}
            circleNumberMap={circleNumberMap}
          />
        </ul>
      </div>
      <Popover trigger={trigger} className={styles.mobile}>
        {(toggle, isShown) => (
          <ul
            className={classNames(styles.navItemsWrap, { [styles.invisible]: !isShown })}
          >
            <NavSections
              sections={sections}
              activePath={activePath}
              pathPrefix={`/applications/${applicationId}`}
              circleNumberMap={circleNumberMap}
              itemAction={toggle}
            />
          </ul>
        )}
      </Popover>

    </div>
  );
};

NewLeftRouterBar.defaultProps = {
  circleNumberMap: {},
  className: undefined,
};

NewLeftRouterBar.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
  circleNumberMap: PropTypes.object,
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
};

export default withRouter(NewLeftRouterBar);
