import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import Loader from 'shared/components/Loader/Loader';
import Icon from 'shared/components/Icon/Icon';
import styles from './styles.module.scss';
import { FETCHING_STATUS } from '../../../../../../constants';

const getContent = (serviceability = null) => {
  const themeContext = useContext(ThemeContext);

  if (isNil(serviceability)) {
    return null;
  }
  const { fetchingStatus, serviceabilityResult } = serviceability;
  switch (fetchingStatus) {
    case FETCHING_STATUS.START: return <Loader round />;
    case FETCHING_STATUS.ERROR: return <Icon name="alert" size="24px" color={themeContext.palette.interactive01} />;
    case FETCHING_STATUS.SUCCESS: {
      return serviceabilityResult
        ? <Icon name="check-circle" size="24px" color={themeContext.palette.success01} />
        : <Icon name="close-circle" size="24px" color={themeContext.palette.interactive01} />;
    }
    default: return '';
  }
};

const serviceabilityInfosSelector = state => get(state, 'application.productSearch.serviceabilityInfos', {});

const ServiceabilityCell = ({ productId }) => {
  const serviceabilitySelector = useMemo(
    () => createSelector(serviceabilityInfosSelector, (infos) => infos[productId]),
    [productId],
  );
  const serviceability = useSelector(serviceabilitySelector);
  return (
    <div className={styles.serviceabilityInfo}>
      {getContent(serviceability)}
    </div>
  );
};

ServiceabilityCell.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default React.memo(ServiceabilityCell);
