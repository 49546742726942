import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import noop from 'lodash/noop';
import get from 'lodash/get';
import shortId from 'shortid';
import ShowMore from 'shared/components/ShowMore/ShowMore';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import SectionTitle from '../SectionTitle';
import styles from './Section.module.scss';

const renderSectionItem = (fieldArray) => {
  const themeContext = useContext(ThemeContext);

  const {
    fields,
    Item,
    editable,
    defaultItem,
    addButtonLabel,
    assets,
    ...others
  } = fieldArray;

  return (
    <>
      {fields.map((field, index) => (
        <Item
          key={fields.get(index).id || fields.get(index).tempId}
          fieldNamePrefix={field}
          {...others}
          itemValue={get(assets, field)}
          handleRemove={() => fields.remove(index)}
          editable={editable}
        />
      ))}
      {editable && (
        <div className={styles.addButtonWrapper}>
          <SecondaryButton
            onClick={() => fields.push({ ...defaultItem, tempId: shortId() })}
            className={styles.addButton}
          >
            <div>{addButtonLabel}</div>
            <Icon name="add" color={themeContext.palette.text01} size="18" />
          </SecondaryButton>
        </div>
      )}
    </>
  );
};

const Section = ({
  title,
  formatSectionHeader,
  fieldName,
  editable,
  Item,
  defaultItem,
  addButtonLabel,
  ...others
}) => {
  const sectionHeader = (
    <Field
      name={fieldName}
      component={SectionTitle}
      title={title}
      format={formatSectionHeader}
    />
  );
  return (
    <FormMetaProvider readOnly={!editable}>
      <div className={styles.sectionWrap}>
        <ShowMore
          name={sectionHeader}
          headerClassName={styles.sectionHeader}
          iconSize="25"
        >
          <FieldArray
            name={fieldName}
            component={renderSectionItem}
            editable={editable}
            Item={Item}
            defaultItem={defaultItem}
            addButtonLabel={addButtonLabel}
            {...others}
          />
        </ShowMore>
      </div>
    </FormMetaProvider>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  Item: PropTypes.elementType.isRequired,
  defaultItem: PropTypes.object,
  addButtonLabel: PropTypes.string,
  formatSectionHeader: PropTypes.func,
};

Section.defaultProps = {
  defaultItem: {},
  addButtonLabel: '',
  formatSectionHeader: noop,
};

export default Section;
