import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './style.module.scss';

const cx = classNames.bind(styles);

const Note = ({
                style,
                className,
                sideBarColor,
                showSideBar,
                children,
}) => {
  const themeContext = useContext(ThemeContext);
  const sideBarColour = sideBarColor || themeContext.palette.information01;

  const mergedStyle = useMemo(() => ({
    ...style,
    borderColor: sideBarColour,
  }), [sideBarColour, style]);
  return (
    <div
      className={cx(className, {
        note: true,
        withSideBar: showSideBar,
      })}
      style={mergedStyle}
    >
      {children}
    </div>
  );
};

Note.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  sideBarColor: PropTypes.string,
  children: PropTypes.node,
  showSideBar: PropTypes.bool,
};

Note.defaultProps = {
  style: {},
  className: undefined,
  children: undefined,
  showSideBar: false,
  sideBarColor: undefined,
};

export default Note;
